import { useContext, useEffect, useState } from 'react';
import { Button, makeStyles, tokens, shorthands, Skeleton, SkeletonItem, Body1Strong, Body1 } from '@fluentui/react-components'
import { CheckmarkCircle24Regular, ErrorCircle24Regular, Open16Regular } from '@fluentui/react-icons'
import { AccountsDataContext } from '../contexts/AccountsDataContext';
import { GRAPH_PERMISSIONS } from '../constants';
import { EngageAppContext } from '../contexts/EngageAppContext';

const useStyles = makeStyles({
    permission: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        //marginTop: tokens.spacingVerticalM
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    consentStatus: {
        width: '50px'
    },
    permissionName: {
        width: '150px'
    },
    description: {
        width: '500px'
    },
    success: {
        color: tokens.colorStatusSuccessForeground1,
        ...shorthands.padding(tokens.spacingHorizontalXS)
    },
    failure: {
        color: tokens.colorStatusDangerForeground1
    },
    loadingItem: {
        marginLeft: tokens.spacingHorizontalS
    }
});

export default function PermissionsSettings() {

    const classes = useStyles();
    const { consentedPermissions, isLoading } = useContext(EngageAppContext);
    const { selectedAccount, accounts } = useContext(AccountsDataContext);

    const [allGraphPermissionsConsentedTo, setAllGraphPermissionsConsentedTo] = useState<boolean>(false);

    const handleConsentClick = () => {
        //trackEvent('leaveFeedbackClicked', {});
        const url = `https://login.microsoftonline.com/${selectedAccount().tenantId}/adminconsent?client_id=${process.env.REACT_APP_API_CLIENT_ID}`
        // TODO: add Environment variable to each environment
        window.open(url, '_blank');
    }


    useEffect(() => {
        (async () => {
            setAllGraphPermissionsConsentedTo(GRAPH_PERMISSIONS.every(x => consentedPermissions.indexOf(x.name) >= 0))

        })();
    }, [consentedPermissions])

    return (
        <>
            <div className={classes.header}>
                <span><Body1Strong>Microsoft Graph</Body1Strong></span>
                <Button onClick={handleConsentClick}
                    icon={<Open16Regular />}
                    iconPosition='after'
                    disabled={isLoading() || allGraphPermissionsConsentedTo}
                >Grant admin consent for Engage</Button>
            </div>
            {GRAPH_PERMISSIONS.map(p => {
                const permissionConsented = consentedPermissions.indexOf(p.name) >= 0;

                return (
                    <div key={p.name} className={classes.permission}>
                        <div key={p.name} className={classes.consentStatus}>
                            {isLoading() &&
                                <Skeleton className={classes.loadingItem}>
                                    <SkeletonItem size={20} shape='circle' />
                                </Skeleton>
                            }
                            {!isLoading() && permissionConsented && <div className={classes.success}><CheckmarkCircle24Regular /></div>}

                            {!isLoading() && !permissionConsented &&
                                <ErrorCircle24Regular
                                    className={classes.failure}
                                />
                            }
                        </div>
                        <div className={classes.permissionName}><Body1>{p.name}</Body1></div>
                        <div className={classes.description}><Body1>{p.description}</Body1></div>
                    </ div>)
            })}
        </>
    )
}