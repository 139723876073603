import * as DOMPurify from 'dompurify';
import { useContext, useEffect, useState } from "react";
import {
    makeStyles,
    shorthands,
    tokens,
    Label,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Button,
    Spinner,

    // accordion display
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel,
} from "@fluentui/react-components";

import { useMediaQuery } from 'react-responsive';

import { useNavigate, useParams } from "react-router-dom";
import { Info24Regular, ArrowLeft20Regular, Add20Filled } from "@fluentui/react-icons";

import { TicketsDataContext } from "../contexts/TicketsDataContext";
import { ContactsDataContext } from "../contexts/ContactsDataContext";
import { AccountsDataContext } from '../contexts/AccountsDataContext';
import { LoggingContext } from "../contexts/LoggingContext";

import StyledCard from "../../components/StyledCard";
import ErrorPage from '../../error-page';
import Editor from '../../components/Editor';
import TicketAttachments from '../../components/TicketAttachments';

import { dateTime } from "../../utils";
import { fonts } from "../../styles";
import { STATUSES, STATUS_TYPES } from '../constants';

const useHeaderStyles = makeStyles({
    headerRow: {
        display: 'flex',
        alignItems: 'center'
    },
    cardTitle: {
        ...fonts.cardTitle,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',

        '& svg': {
            marginLeft: tokens.spacingHorizontalS
        }
    },
    dismissIcon: {
        cursor: 'pointer',
        marginRight: tokens.spacingHorizontalM
    }
});

const useStyles = makeStyles({
    activitiesCard: {
        marginTop: tokens.spacingVerticalL
    },
    ticketDescription: {
        marginTop: tokens.spacingVerticalS,
        marginBottom: tokens.spacingVerticalS,
        maxHeight: '250px',
        overflowY: 'auto'
    },
    commentSaveCancel: {
        display: 'flex',
        marginTop: tokens.spacingVerticalM
    },
    saveButton: {
        marginRight: tokens.spacingHorizontalS
    },
    activityDate: {
        marginRight: tokens.spacingHorizontalS,
        minWidth: '150px'
    },
    activityTitle: {
        display: 'flex',
        width: '100%',
    },
    activityNavigationMobile: {
        display: 'flex',
        flexDirection: 'column',
    },
    activityNavigation: {
        display: 'flex',
        flexDirection: 'row',
    },
    activitySubject: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginRight: tokens.spacingHorizontalL

    },
    activityBody: {
    },
    activityContact: {
        width: '250px',
        overflowX: 'hidden',
        // TODO Verify disabling this
        // textWrap: 'noWrap',
        textOverflow: 'ellipsis',
        marginRight: tokens.spacingHorizontalS

    },
    savingSpinner: {
        marginLeft: tokens.spacingHorizontalS
    },
    activityItem: {
        ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralStroke1),
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover
        }

    },
    accordionPanel: {
        marginLeft: tokens.spacingHorizontalXL,
        marginBottom: tokens.spacingVerticalM,
        ...shorthands.borderLeft('3px', 'solid', tokens.colorNeutralForeground2BrandSelected),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL),

    }
});

const TicketDetails = (props: any) => {

    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    const [ticketDetails, setTicketDetails] = useState<any>(null);
    const [addCommentPressed, setAddCommentPressed] = useState(false);
    const [comment, setComment] = useState('');
    const [commentIsSaving, setCommentIsSaving] = useState(false);
    const [hideDescription, setHideDescription] = useState(true);
    const [error, setError] = useState<any>(null);

    // Attachments
    const [files, setFiles] = useState<any[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const navigate = useNavigate();

    const { ticketId } = useParams();
    const { getTicketDetails, ticketsLoading, ticketError, tickets, setTickets, createComment, uploadAttachments } = useContext(TicketsDataContext);
    const { contact } = useContext(ContactsDataContext);
    const { accounts, accountError } = useContext(AccountsDataContext);
    const { trackPageView, trackTraceVerbose, trackEvent, trackException, getSessionId } = useContext(LoggingContext);

    const handleAddCommentClick = () => {
        setAddCommentPressed(true);
    }

    const handleCancelComment = () => {
        setAddCommentPressed(false);
        setComment('');
    }

    const validateModel = () => {
        let validationErrorsLocal = [];

        if (comment === '') {
            validationErrorsLocal.push('Comment is required');
        }

        return validationErrorsLocal;
    }

    const handleSaveComment = () => {
        let validationErrorsLocal = validateModel();

        if (validationErrorsLocal.length > 0) {
            return;
        }

        setCommentIsSaving(true);
        const ticketComment = DOMPurify.sanitize(comment);

        var form = new FormData();
        form.append('comment', ticketComment);

        createComment(ticketId, form).then(() => {
            trackEvent('ticketCommentCreated', { ticketId });
            setCommentIsSaving(false);

            let newTicketDetails = { ...ticketDetails };
            newTicketDetails.ticketActivities = [{
                description: ticketComment,
                descriptionNoHtml: ticketComment, activityBy: contact.fullName, activityDate: new Date().toISOString()
            },
            ...newTicketDetails.ticketActivities];

            setTicketDetails(newTicketDetails);

            handleCancelComment();
        }).catch((err: any) => {
            setCommentIsSaving(false);
            trackException(err);
        });

    };

    useEffect(() => {

        (async () => {

            if (accounts.length === 0 || !contact) {
                trackTraceVerbose(`ticketDetails-useEffect-guardClause: deps[accounts, contacts, ticketId]`);
                return;
            }

            let details = await getTicketDetails(ticketId as string);

            if (!details || ticketError) {
                const sessionId = getSessionId();
                setError(
                    <div>
                        <div>Error retrieving ticket details. Please try again later.</div>
                        <div>If the problem persists, please contact support. </div>
                        <br />
                        <div><a href={`mailto:help@greatservice.com?subject=Engage Support Help: ${sessionId}`}>help@greatservice.com</a></div>
                    </div>)
                trackTraceVerbose(`ticketDetails-useEffect-ticketError: ${ticketError}`);
                return;
            }

            var latestActivity = details.ticketActivities.filter((x: any) => x.isLatest)[0];

            /// TODO What if there is no latest activity?
            if (latestActivity) {
                // Display ticket activities from most recent to oldest
                details.ticketActivities.reverse();
            }
            setTicketDetails(details);

            if (details.attachments.length > 0) {
                setFiles(details.attachments);

            }

            // Track page view by ticket status
            let status = details.statusType === 'Closed' ? 'Closed' : 'Open';
            if (details.status === STATUSES.ON_HOLD) {
                status = STATUSES.ON_HOLD;
            }

            trackPageView(`ticketDetails-${status}`);
        })();

    }, [accounts, contact]);

    const onDismissClick = () => {
        navigate('/');
    }

    const handleAttachmentUpload = async () => {

        if (!ticketId) {
            return;
        }

        setIsUploading(true);
        var attachmentFormData = new FormData();
        var newFiles = files.filter((attachment) => !attachment.id);

        newFiles.forEach((attachment) => {
            attachmentFormData.append('Attachments', attachment);
        });

        var results = await uploadAttachments(ticketId as string, attachmentFormData) as any;

        console.log('uploadAttachments', results);
        let updatedFiles = [...files];
        const resultsJson = await results.json();
        resultsJson.forEach((attachment: any) => {
            const updatedFileIndex = updatedFiles.findIndex((file) => file.name === attachment.name && !file.id);
            updatedFiles[updatedFileIndex] = attachment;
        });

        setFiles(updatedFiles);

        trackEvent('ticketAttachmentsUploaded', { ticketId });
        setIsUploading(false);
    }

    const InfoPopover = (props: any) => {

        const { status, createdAt, closedAt, contactName, updatedAt } = props.props;

        return (<>
            <Popover openOnHover={false} withArrow positioning={{ position: "below", align: "end" }}>
                <PopoverTrigger>
                    <div>
                        <Info24Regular />
                    </div>
                </PopoverTrigger>
                <PopoverSurface>
                    <div>
                        <div>
                            <Label>Status: </Label><span>{status}</span>
                        </div>
                        <div>
                            <Label>Created On: </Label><span>{dateTime(createdAt)}</span>
                        </div>
                        {status === 'Closed' &&
                            <div>
                                <Label>Resolved at: </Label><span>{dateTime(closedAt)}</span>
                            </div>
                        }

                        <div>
                            <Label>Requested By: </Label><span>{contactName}</span>
                        </div>
                        <div>
                            <Label>Last Modified on: </Label><span>{dateTime(updatedAt)}</span>
                        </div>
                    </div>
                </PopoverSurface>
            </Popover>
        </>)
    }

    const getTitle = () => {

        if (error) {
            const sessionId = getSessionId();
            return (
                <div className={headerClasses.headerRow}>
                    <div>
                        <ArrowLeft20Regular className={headerClasses.dismissIcon} onClick={onDismissClick} />
                    </div>
                    <div>Error Encountered - {sessionId}</div>
                </div>
            )
        }

        if (!ticketDetails)
            return (<div></div>);

        return (
            <div className={headerClasses.headerRow}>
                <div>
                    <ArrowLeft20Regular className={headerClasses.dismissIcon} onClick={onDismissClick} />
                </div>
                <div className={headerClasses.cardTitle}>
                    <span>{ticketDetails.statusType === 'Closed' ? 'Closed - ' : ''}{ticketDetails.subject} - {ticketDetails.serviceDeskId}</span>
                    <InfoPopover props={ticketDetails} />
                </div>
            </div>
        );
    };

    const getAccordionDisplay = () => {
        return (
            <Accordion collapsible>{
                ticketDetails?.ticketActivities.map((activity: any) => {
                    return (
                        <AccordionItem value={activity.id}>
                            <AccordionHeader className={classes.activityItem}>
                                <div className={classes.activityTitle}>
                                    <div className={isDesktopOrLaptop ? classes.activityNavigation : classes.activityNavigationMobile}>
                                        <div className={classes.activityDate}>{dateTime(activity.activityDate)}</div>
                                        <div className={classes.activityContact}>
                                            <strong>{activity.activityBy}</strong>
                                        </div>
                                    </div>
                                    {isDesktopOrLaptop &&
                                        <div className={classes.activitySubject}>{activity.descriptionNoHtml}</div>
                                    }
                                </div>
                            </AccordionHeader>
                            <AccordionPanel className={classes.accordionPanel}>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity.description) }}></div>
                            </AccordionPanel>

                        </AccordionItem>
                    )
                })
            }
            </Accordion>
        )
    }

    const getFooter = () => {

        const unsavedAttachments = files.filter((attachment) => !attachment.id);

        return (
            <>
                {unsavedAttachments.length > 0 &&
                    <Button onClick={handleAttachmentUpload}
                        disabled={isUploading}
                        appearance={isUploading ? 'secondary' : 'primary'}>
                        {isUploading ?
                            <Spinner
                                size='tiny'
                                label='Saving...' />
                            : 'Upload Attachment(s)'
                        }
                    </Button>

                }
            </>
        );
    }

    const getActions = () => {

        if (!ticketDetails) {
            return (<div></div>);
        }

        if (ticketDetails?.contactId !== contact?.id) {
            return (<div></div>);
        }

        if (ticketDetails.statusType === STATUS_TYPES.CLOSED) {
            return (
                <div></div>
            )
        }

        return (
            <Button icon={<Add20Filled />}
                onClick={handleAddCommentClick}>Comment</Button>
        )
    }

    return (
        <>
            {accountError && <ErrorPage sessionId={getSessionId()} />}
            {!accountError &&
                <StyledCard
                    title={getTitle()}
                    isLoading={ticketsLoading}
                    error={error}
                    footer={getFooter()}
                >
                    {ticketDetails &&
                        <>
                            <div className={classes.activityBody}>
                                {ticketDetails.description &&
                                    <div>
                                        <div
                                            className={classes.ticketDescription}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ticketDetails.description) }}
                                        ></div>
                                    </div>
                                }
                            </div>
                            <TicketAttachments files={files} onFilesChange={(attachments: any) => setFiles(attachments)} />
                        </>
                    }
                </StyledCard>
            }
            <StyledCard
                className={classes.activitiesCard}
                title={"Activities"}
                actions={getActions()}
                isLoading={ticketsLoading}>
                {
                    <>
                        <div>
                            {addCommentPressed && !commentIsSaving &&
                                <div>
                                    <Editor
                                        editorText={comment}
                                        onEditorChange={(newComment: string) => setComment(newComment)}
                                    />
                                    <div className={classes.commentSaveCancel}>
                                        <div className={classes.saveButton}>
                                            <Button appearance='secondary' disabled={commentIsSaving || validateModel().length > 0} onClick={handleSaveComment}>Save</Button>
                                        </div>
                                        <div>
                                            <Button appearance='secondary' onClick={handleCancelComment}>Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {commentIsSaving && <Spinner className={classes.savingSpinner} label='Saving...' />}
                        </div>
                        {
                            getAccordionDisplay()
                        }
                    </>
                }
            </StyledCard>
        </>
    )
}

export default TicketDetails;