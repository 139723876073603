import {
    Button,
    Dropdown, Option,
    Input, Table, TableHeader, TableBody, TableRow, TableCell,
    Label,
    SpinButton,
    makeStyles,
    Textarea,
    tokens,
    Spinner,
    Body1,
    Caption1,
    Divider,
    Body1Strong,
    shorthands
} from "@fluentui/react-components"
import { useLicensingData } from "../../hooks/useLicensingData"
import { EngageAppContext } from "../contexts/EngageAppContext"
import { AccountsDataContext } from "../contexts/AccountsDataContext"
import { ContactsDataContext } from "../contexts/ContactsDataContext"

import { Add24Regular, ArrowLeft20Regular, Dismiss20Regular } from "@fluentui/react-icons"
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StyledCard from "../../components/StyledCard";
import { formatUSD } from "../../utils";
import { TicketsDataContext } from "../contexts/TicketsDataContext";

const useStyles = makeStyles({
    tableHeight: {
        height: '300px',
        oveflowY: 'scroll'
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        marginLeft: tokens.spacingHorizontalM
    },
    nameColumn: {
        width: '500px'
    },
    dismissColumn: {
        width: '36px'
    },
    quantityColumn: {
        width: '150px'
    },
    searchBox: {
        marginBottom: tokens.spacingVerticalL
    },
    product: {
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingHorizontalXS, tokens.spacingVerticalM),
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover
        }
    }
});

export default function RequestNewSubscription() {

    const navigate = useNavigate()

    const { getProducts, getProductPricing } = useLicensingData();
    const { createTicket, setTickets, tickets } = useContext(TicketsDataContext);
    const { contact } = useContext(ContactsDataContext);
    const { selectedAccount } = useContext(AccountsDataContext);
    const { adminSiteSettings } = useContext(EngageAppContext);
    const [products, setProducts] = useState([]);
    const [visibleProducts, setVisibleProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [page, setPage] = useState(0);
    const numItems = 20;

    const classes = useStyles();

    const onInputChange = ((ev: any, data: any) => {
        const filteredProducts = products.filter((x: any) => {
            return x.name.toLowerCase().includes(data.value.toLowerCase())
        });

        setVisibleProducts(filteredProducts.slice(page, numItems))
    })

    const onDismissClick = () => {
        navigate('/subscriptions');
    }


    useEffect(() => {
        (
            async () => {
                setIsLoading(true);
                const productsResponse = await getProducts();

                setPage(0);
                setProducts(productsResponse);
                setSelectedProducts([]);
                setVisibleProducts(productsResponse.slice(page, numItems))
                setIsLoading(false);
            }
        )();
    }, []);

    const isDisabled = () => {
        if (selectedProduct === undefined || selectedProduct === null) {
            return true;
        }

        if (!selectedProduct.selectedSubscriptionType) {
            return true;
        }

        if (!selectedProduct.quantity || selectedProduct.quantity <= 0) {
            return true;
        }

        return false;
    }

    const handleProductClick = (productId: string) => (data: any) => {

        console.log('dater', data);
        setIsLoading(true);

        const clickedProd: any[] = products.filter((x: any) => x.id === productId)
        getProductPricing(productId).then(x => {
            clickedProd[0].pricing = x;
            setSelectedProducts([...selectedProducts, ...clickedProd])
            const selected = {
                ...clickedProd[0],
                selectedSubscriptionType: null
            }
            setSelectedProduct(selected);
            setIsLoading(false);
        })
    }

    const submitTicketRequest = async () => {
        var account = selectedAccount();
        setIsSubmitting(true);
        const details =
            `**Account**: ${account.tenantName}\n\n`
            + `**Ordered by**: ${contact.fullName}\n\n`
            + `**Pax8 Product**: ${selectedProduct.name} \(${selectedProduct.id}\)\n\n`
            + `**SubscriptionType**: ${selectedProduct.selectedSubscriptionType}\n\n`
            + `**Quantity**: ${selectedProduct.quantity}\n\n`
            + `**Notes**: ${selectedProduct.notes}`;

        let form = new FormData();
        form.append('Subject', `Engage : New Subscription Request: ${selectedProduct.name}`);
        form.append('Description', details);

        const createdTicket: any = await createTicket(form)
        createdTicket.contactName = contact.fullName;
        const newTicketState = [...tickets, createdTicket]
        setTickets(newTicketState);

        setIsSubmitting(false);
        // Need to handle adding the ticket back...
        navigate('/');
    }

    const onOptionSelected = (event: any, data: any) => {
        const modified = { ...selectedProduct };

        modified.selectedSubscriptionType = data.optionValue
        setSelectedProduct(modified);
    }

    const onProductDismiss = (event: any) => {
        setSelectedProduct(null);
        setSelectedProducts([]);
    }

    const onSpinboxChange = (event: any, data: any) => {

        const modified = { ...selectedProduct }
        console.log('event', event);
        modified.quantity = data.value ?? data.displayValue;

        setSelectedProduct(modified);
    }

    const onTextAreaChange = (event: any, data: any) => {
        const modified = { ...selectedProduct }
        modified.notes = data.value

        setSelectedProduct(modified);
    }

    const getProductDropdown = (product: any) => {
        return (<>

            <Dropdown onOptionSelect={onOptionSelected}>
                {product.pricing.map((p: any) => {

                    if (!adminSiteSettings.allowedNewCommitmentTerms.some((val: any) => val === p.commitmentTerm)) {
                        return;
                    }

                    let optionText;
                    if (p.commitmentTerm !== null) {
                        optionText = `Billing Term: ${p.billingTerm}, Commitment Term: ${p.commitmentTerm}, MSRP: ${formatUSD(p.rates[0].suggestedRetailPrice, 2)}`;
                    }
                    else {

                        optionText = `Billing Term: ${p.billingTerm}, MSRP: ${formatUSD(p.rates[0].suggestedRetailPrice, 2)}`;
                    }
                    return (<Option>{optionText}</Option>)
                })}

            </Dropdown>
        </>)
    }

    return (
        <>

            <>
                <StyledCard
                    title={<div className={classes.cardHeader}>
                        <Button icon={<ArrowLeft20Regular />} appearance="transparent" onClick={onDismissClick} />
                        <div className={classes.title}>Request Subscription</div>
                    </div>}
                    footer={<Button appearance="primary" onClick={submitTicketRequest} disabled={isDisabled()} >
                        {
                            isSubmitting ?
                                <Spinner
                                    size='tiny'
                                    label='Saving...'
                                    appearance="inverted" />
                                : 'Request Subscription'
                        }
                    </Button>}
                    isLoading={isLoading}>

                    {!selectedProduct &&
                        <>
                            <Input className={classes.searchBox} onChange={onInputChange} placeholder={"Search Pax8 Subscription Catalog"} />
                            {visibleProducts.map((p: any) =>
                                <>
                                    <div key={p.id} onClick={handleProductClick(p.id)} className={classes.product}>
                                        <div><Body1Strong>{p.name}</Body1Strong></div>
                                        <div><Body1>{p.shortDescription}</Body1></div>
                                    </div>
                                    <Divider />
                                </>
                            )}
                        </>
                    }

                    {selectedProduct &&
                        <>
                            <div><Body1Strong>{selectedProduct.name}</ Body1Strong></div>
                            <div><Body1>{selectedProduct.shortDescription}</Body1></div>

                            <div>
                                <Label required><Body1Strong>Subscription Type</Body1Strong></Label>
                                <div>
                                    {getProductDropdown(selectedProduct)}
                                </div>
                            </div>
                            <div>
                                <Label required>
                                    <Body1Strong>Quantity</Body1Strong>
                                </Label>
                                <div>
                                    <SpinButton id={'tetetet'} onChange={onSpinboxChange} min={1} />
                                </div>
                            </div>
                            <Label>
                                <Body1Strong>Notes (Optional)</Body1Strong>
                            </Label>
                            <Textarea onChange={onTextAreaChange} />
                        </>
                    }

                </StyledCard>
            </>
        </>
    )
}