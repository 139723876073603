import { useEngageApp } from '../../hooks/useEngageApp';
import type { AdminSiteSettings, TenantSiteSettings } from '../../hooks/useEngageApp';
import { createContext} from 'react';

const defaultAdminSiteSettings:AdminSiteSettings = {
    allowedNewCommitmentTerms: [""],
    isLicensingModuleEnabled: false,
    isSubscriptionRightsizingEnabled: false,
    maxCalculatedOrderCostThreshold: 0
}

const defaultTenantSiteSettings:TenantSiteSettings = {
    licensingNotificationRecipients: [""]
}


const engageAppContextDefault = {
    isLoading: (componentName:string = "") => false,
    loadingArray: ["Default"],
    setIsComponentLoading: (componentName:string, loadingValue:boolean) => { },
    adminSiteSettings: defaultAdminSiteSettings,
    updateAdminSiteSettings: (settings:AdminSiteSettings) => { },
    tenantSiteSettings: defaultTenantSiteSettings,
    updateTenantSiteSettings: (settings:TenantSiteSettings) => { },
    consentedPermissions: [""]

}

export const EngageAppContext = createContext(engageAppContextDefault);

//export const EngageAppContext = createContext(
   //{
    //isLoading: false,
    //setIsLoading: (loadingValue:boolean) => { },
    //adminSiteSettings: test,
    //setAdminSiteSettings: (settings:any) => { } 
//}
//);



export const EngageAppProvider = ({children}: {children: any}) => {

    const { isLoading, loadingArray, setIsComponentLoading, adminSiteSettings, updateAdminSiteSettings, tenantSiteSettings, updateTenantSiteSettings, consentedPermissions } = useEngageApp();

        return (
            <EngageAppContext.Provider value={{ isLoading, loadingArray, setIsComponentLoading, adminSiteSettings, updateAdminSiteSettings, tenantSiteSettings, updateTenantSiteSettings, consentedPermissions}}>
                {children}
            </EngageAppContext.Provider>
        );

}