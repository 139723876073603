import { useEffect, useState } from 'react';
import { Table, TableHeader, TableRow, TableCell, TableBody, makeStyles, tokens, shorthands, Checkbox, Button, Divider, SpinButton, Label } from '@fluentui/react-components';
import StyledCard from '../../components/StyledCard';
import { useLicensingData, } from '../../hooks/useLicensingData';
import { AccountsDataContext } from '../contexts/AccountsDataContext';
import { fonts } from '../../styles'
import { dateTime, date, dateUTC } from '../../utils'

import { Sparkline, LineChart, ISparklineProps, DataVizPalette, IChartProps, ILineChartProps, getColorFromToken, DonutChart, IDonutChartProps, IChartDataPoint } from '@fluentui/react-charting';
import { Add24Regular, Open24Regular, ArrowLeft20Regular } from '@fluentui/react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Currency from '../../components/Currency';
import SparkLine from '../../components/Sparkline';
import EngageTable from '../../components/EngageTable';

const useStyles = makeStyles({

    table: {
        minWidth: '1600px'
    },

    productGrouping: {
        backgroundColor: tokens.colorBrandBackground2,
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        ...shorthands.borderRadius('5px'),

        display: 'flex',
        flexDirection: 'column',
        height: '150px'
        //        ...shorthands.margin('0px', tokens.spacingVerticalL, tokens.spacingHorizontalL)

    },
    productGroupingName: {
        marginBottom: tokens.spacingVerticalL
    },
    productDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: '150px'
    },
    columnProductName: {
        width: '400px',
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL)
    },
    columnSubscriptionQuantity: {
        width: '80px',
        textAlign: 'center'
    },
    pax8ProductNameColumn: {
        textOverflow: 'ellipsis',
        overflowX: 'clip',
        textWrap: 'nowrap',
        width: '350px'
    },
    left: {
        display: 'flex',
        justifyContent: 'left',
        marginRight: tokens.spacingHorizontalL

    },
    right: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'flex-start'
    },
    row: {
        verticalAlign: 'top',
        '& td': {
            paddingTop: tokens.spacingVerticalL
        }
    },
    currencyColumn: {
        textAlign: 'right'
    },
    totalLine: {
        ...fonts.title
    },
    labels: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: "center",
        '& Label': {
            ...fonts.label,
            marginRight: tokens.spacingHorizontalM
        },
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        cursor: 'pointer'
    },
    title: {
        marginLeft: tokens.spacingHorizontalM
    }
});


export default function ManageSubscriptions() {

    const { state } = useLocation();
    const navigate = useNavigate();
    console.log('state', state)
    const products = state.products;
    console.log(products)
    const logFilePrefix = 'ManageSubscriptions';

    const classes = useStyles();

    //const { accounts } = useContext(AccountsDataContext);
    const [engageProducts, setEngageProducts] = useState<any>([]);

    useEffect(() => {

        setEngageProducts(products);
        // (
        //        async () => {
        //console.log('useEffect-subscriptionData')

        //var data = await getSubscriptionData()
        //setEngageProducts(data);

        //var billingWidgetData = await getBillingWidgetData();
        //setBillingWidgetData(billingWidgetData);
        //}
        //  )();
    }, []);
    //}, [accounts]);

    const onDismissClick = () => {
        navigate('/subscriptions');
    }

    return (

        <div>


            <>
                <StyledCard
                    title={<div className={classes.cardHeader}>
                        <ArrowLeft20Regular onClick={onDismissClick} />
                        <div className={classes.title}>Manage Subscriptions</div>
                    </div>}
                //footer={selectedProducts.length > 0 && getFooter()}
                //actions={<Button icon={<Add24Regular />}>Product</Button>}
                >
                    <EngageTable className={classes.table}>
                        <TableHeader>
                            <TableRow>
                                <TableCell className={classes.columnProductName}></TableCell>
                                <TableCell>Commitment Term</TableCell>
                                <TableCell>Commitment Term Enddate</TableCell>
                                <TableCell className={classes.columnSubscriptionQuantity}>Quantity</TableCell>
                                <TableCell className={classes.currencyColumn}>Price</TableCell>
                                <TableCell className={classes.currencyColumn}>MSRP Monthly</TableCell>
                                <TableCell className={classes.currencyColumn}>MSRP Annually</TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {engageProducts.map((product: any) => {
                                {
                                    return (
                                        product.subscriptions.map((subscription: any, index: number) => {


                                            return (<>
                                                <TableRow className={classes.row}>
                                                    {(product.subscriptions.length === 1 || index + 1 < product.subscriptions.length) && (
                                                        <TableCell className={classes.columnProductName} rowSpan={product.subscriptions.length}>
                                                            <div className={classes.productGrouping}>
                                                                <div className={classes.productGroupingName}>
                                                                    <div>{product.productName}</div>
                                                                </div>
                                                                <div className={classes.productDetails}>
                                                                    <div className={classes.labels}>
                                                                        <Label>Provisioned:</Label>
                                                                        {product.history.length > 0 &&
                                                                            <div>
                                                                                <SparkLine datapoints={product.history.map((x: any) => x.subscribedSkuQuantity)} showLegend={true} />
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className={classes.labels}>
                                                                        <Label>Unassigned:</Label>
                                                                        {product.history.length > 0 &&
                                                                            <div>
                                                                                <SparkLine datapoints={product.history.map((x: any) => x.subscribedSkuQuantity - x.subscribedSkuAssignedQuantity)} showLegend={true} />

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className={classes.labels}>
                                                                        <Label>Billed Monthly:</Label>
                                                                        <div><Currency dollarAmount={product.totalBilledMonthly} /></div>
                                                                    </div>

                                                                    <div className={classes.labels}>
                                                                        <Label>Billed Annually:</Label>
                                                                        <div><Currency dollarAmount={product.totalBilledAnnually} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    )
                                                    }
                                                    { /*<TableCell className={classes.pax8ProductNameColumn}>{subscription.subscriptionName}</TableCell> */ }
                                                    <TableCell>{subscription.commitmentTerm.term}</TableCell>
                                                    <TableCell>{dateUTC(subscription.commitmentTerm.endDate)}</TableCell>
                                                    <TableCell className={classes.columnSubscriptionQuantity}><SpinButton value={subscription.quantity} /></TableCell>
                                                    <TableCell className={classes.currencyColumn}><Currency dollarAmount={subscription.price} /></TableCell>
                                                    <TableCell className={classes.currencyColumn}><Currency dollarAmount={subscription.billingTerm === "Monthly" ? subscription.price * subscription.quantity : 0} /></TableCell>
                                                    <TableCell className={classes.currencyColumn}><Currency dollarAmount={subscription.billingTerm === "Annual" ? subscription.price * subscription.quantity : 0} /></TableCell>
                                                </TableRow>
                                            </>)
                                        })
                                    )
                                }
                            })
                            }
                            <TableRow className={classes.totalLine}>
                                <TableCell className={classes.pax8ProductNameColumn}>Total:</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell className={classes.columnSubscriptionQuantity}></TableCell>
                                <TableCell className={classes.currencyColumn}></TableCell>
                                <TableCell className={classes.currencyColumn}><Currency dollarAmount={engageProducts.reduce((total: any, sub: any) => total + sub.totalBilledMonthly, 0)} /></TableCell>
                                <TableCell className={classes.currencyColumn}><Currency dollarAmount={engageProducts.reduce((total: any, sub: any) => total + sub.totalBilledAnnually, 0)} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </EngageTable>
                </StyledCard>
                <br />
                <br />
                <br />
                { /*
                    <StyledCard
                    title="Manage Subscriptions"
                //footer={selectedProducts.length > 0 && getFooter()}
                //actions={<Button icon={<Add24Regular />}>Product</Button>}
                >
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Commitment Term</TableCell>
                                <TableCell>Commitment EndDate</TableCell>
                                <TableCell>Billed</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                engageProducts.map((product: any) => {
                                    return (
                                        <>
                                            {product.subscriptions.map((subscription: any) => {
                                                return (
                                                    <>
                                                        <TableRow>
                                                            <TableCell>{subscription.subscriptionName}</TableCell>
                                                            <TableCell>{subscription.commitmentTerm.term}</TableCell>
                                                            <TableCell>{date(subscription.commitmentTerm.endDate)}</TableCell>
                                                            <TableCell>{subscription.billingTerm}</TableCell>
                                                            <TableCell><SpinButton
                                                                className={classes.quantitySpinBox}
                                                                value={subscription.quantity} />
                                                            </TableCell>
                                                            <TableCell>${subscription.price.toFixed(2)}</TableCell>
                                                            <TableCell>{(subscription.price * subscription.quantity).toFixed(2)}</TableCell>
                                                        </TableRow>
                                                    </>)
                                            })}

                                        </>)
                                })
                            }
                        </TableBody>
                    </Table>

                </StyledCard> */}
                <br />
                { /*
                <StyledCard
                    title="Manage Subscriptions"
                //footer={selectedProducts.length > 0 && getFooter()}
                //actions={<Button icon={<Add24Regular />}>Product</Button>}
                >
                    {
                        engageProducts.map((product: any) => {
                            return (
                                <>
                                    <Divider />
                                    <div className={classes.productName}>{product.productName}</div>
                                    <div>
                                        {product.subscriptions.map((subscription: any) => {
                                            return (
                                                <>
                                                    <div className={classes.subscriptionRow}>
                                                        <div className={classes.calculationLayout}>
                                                            <span><SpinButton
                                                                className={classes.quantitySpinBox}
                                                                value={subscription.quantity} />
                                                            </span>
                                                            <span>X</span>
                                                            <span>${subscription.price.toFixed(2)}</span>
                                                            <span>=</span>
                                                            <span>${(subscription.price * subscription.quantity).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Label weight='semibold'>Commitment Term</Label> <div>{subscription.commitmentTerm.term}</div>
                                                    </div>
                                                    <div>Commitment Term EndDate: {dateTime(subscription.commitmentTerm.endDate)}</div>
                                                    <div>Commitment Term EndDate: {subscription.commitmentTerm.endDate}</div>
                                                    <div>Billed: {subscription.billingTerm}</div>
                                                </>)
                                        })}

                                    </div>
                                </>
                            )
                        })
                    }
                    {
                        engageProducts?.length > 0 ? <div>{JSON.stringify(engageProducts[0].subscriptions[0])}</div> : ""
                    }
                </StyledCard> */}
            </>
        </div >
    );
}