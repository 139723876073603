import {
  Card,
  CardHeader,
  makeStyles,
  tokens
} from '@fluentui/react-components'

import {
  AreaChart
}
  from '@fluentui/react-charting';
import StyledCard from '../StyledCard';
import NoData from './NoData';
import { CategoryTimeSeriesData } from '../../types/reportingTypes';

const colors = [
  tokens.colorBrandForeground1,
  tokens.colorPaletteRedForeground1,
  tokens.colorPaletteLightTealForeground2,
  tokens.colorPaletteGreenForeground1,
  tokens.colorPaletteMarigoldForeground1,
  tokens.colorPaletteSeafoamForeground2,
  tokens.colorPaletteDarkOrangeForeground1,
  tokens.colorPaletteLightGreenForeground1,
  tokens.colorPaletteLavenderForeground2,
  tokens.colorPaletteBlueForeground2,
  //tokens.colorBrandForeground2,
  //tokens.colorPaletteRedForeground2,
  //tokens.colorPaletteDarkOrangeForeground2,
  //tokens.colorPaletteYellowForeground2,
  //tokens.colorPaletteGreenForeground2,
  //tokens.colorPaletteBerryForeground2,
  //tokens.colorPaletteBerryForeground1,
  //tokens.colorPaletteLightGreenForeground2,
  //tokens.colorPaletteBerryForeground1,
  //tokens.colorPaletteMarigoldForeground2,
  //tokens.colorPaletteSteelForeground2,
  //tokens.colorPaletteYellowForeground1,
]

function TicketsByCategory({ casesOverTimeData }: { casesOverTimeData: CategoryTimeSeriesData[] }) {

  let colorIndex = 0;
  const chartData = {
    chartTitle: 'Line Chart',
    styles: {
    },
    // There is an issue with the charting library where if the dates are too far apart it breaks
    // Leaving this slice in until the api is filtered to return last 30 days
    // TODO: Remove this slice
    lineChartData: casesOverTimeData.map((category: any) => {
      const color = colors[colorIndex];
      colorIndex++;
      return {


        legend: category.name,
        color: color,
        data: category.series.map((x: any) => {
          return {
            x: new Date(x.date),
            y: x.value
          }
        })
      }
    }),
    color: tokens.colorBrandForegroundInverted,
    lineOptions: {
      lineBorderWidth: '4'
    },
  }

  return (
    <StyledCard title='Tickets By Category' >
      { chartData.lineChartData.length !== 0 ?
      <AreaChart
        data={chartData}
        legendsOverflowText={'Overflow Items'}
        legendProps={{
          allowFocusOnLegends: true,
        }}
        
      />: <NoData /> }
    </StyledCard>
  );
}

export default TicketsByCategory;