import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({

});

export default function NoSubscriptionData() {
    return (
        <>
            <div>
                <p>Looks like you don't have any active subscriptions</p>
                <p>Press the "+ Subscription" button to get started managing subscriptions with Engage by Summit Technology today! </p>
            </div>
        </>
    );
}