import { useState } from "react";
import { makeStyles, shorthands, FluentProvider, teamsLightTheme } from "@fluentui/react-components";

import { EmojiSadRegular, EmojiAngryRegular, ArrowLeft32Regular, EmojiMemeRegular } from "@fluentui/react-icons";
import { useRouteError, useNavigate } from "react-router-dom";

import { fonts } from "./styles";
import StyledCard from "./components/StyledCard";
import { ERROR_CODES } from "./app/constants";

const useStyles = makeStyles({
  errorPage: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    ...fonts.body,

    '& h1': {
      fontSize: '4rem',
      ...shorthands.margin(0)
    },

    '& p': {
      fontSize: '1.5rem',
      ...shorthands.margin(0)
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    '& h2': {
      marginLeft: '10px',
    }
  },
  backIcon: {


  },
  icon: {
    height: '100px',
    width: '100px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

export default function ErrorPage({ sessionId, errorType }: { sessionId?: string | undefined | null, errorType?: string | undefined | null }) {
  const navigate = useNavigate();
  const error: any = useRouteError();

  let errorJSX;
  switch (errorType) {
    case 'AuthError':
      errorJSX =
        <>
          <p>Authentication Error. Please try again later.</p>
        </>
      break;
    case ERROR_CODES.SERVICE_UNAVAILABLE:
      errorJSX =
        <>
          <p>Engage API Service Unavailable.  Please try again later</p>
        </>
      break;
    default:
      errorJSX =
        <>
          <p>An unexpected error has occurred.</p>
          <p>Please try again later.</p>
        </>
  };

  const classes = useStyles();

  const [icon, setIcon] = useState(<EmojiSadRegular className={classes.icon} />);
  const [iconClickCounter, setIconClickCounter] = useState(0);
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const supportPhoneNumber = process.env.REACT_APP_SUPPORT_PHONE;
  const getIcon = () => {
    return icon;
  }

  const handleClick = () => {
    setIconClickCounter(iconClickCounter + 1);

    if (iconClickCounter < 10) {
      setIcon(<EmojiAngryRegular className={classes.icon} />);
    }
    else {
      setIcon(<EmojiMemeRegular className={classes.icon} />)
    }
  }

  const handleArrowClick = () => {
    navigate('/', { replace: true });
    navigate(0);
  }

  const header = () => {
    return (
      <div className={classes.header}>
        <ArrowLeft32Regular className={classes.backIcon} onClick={handleArrowClick} />
        <h2>Something went wrong...</h2>
      </div>
    )
  }

  const footer = () => {
    return (
      <div className={classes.footer}>
        {process.env.REACT_APP_BUILD_NUMBER}
      </div>
    );
  }

  return (
    <div className={classes.errorPage} id="error-page">
      <FluentProvider
        id='errorPage'
        theme={teamsLightTheme}>
        <StyledCard
          title={header()}
          footer={footer()}>
          <div onClick={handleClick} className={classes.icon}>{getIcon()}</div>
          {errorJSX}
          <div>
            <h3>
              For questions or immediate assistance, please contact Summit support
            </h3>
            {sessionId &&
              <h4>
                SessionId: {sessionId}
              </h4>
            }
            <div>
              <span><a href={`mailto:${supportEmail}?subject=Engage Support Help: ${sessionId}`}>{supportEmail}</a></span>
            </div>
            <div>
              <span><a href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</a></span>
            </div>
          </div>
        </StyledCard>
      </FluentProvider>
    </div>
  );
}