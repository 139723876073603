import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TabList, Tab, Divider, makeStyles, tokens, Button, Skeleton, SkeletonItem } from "@fluentui/react-components"
import { Add24Regular } from "@fluentui/react-icons";
import type {
    SelectTabData,
    SelectTabEvent,
    TabValue,
} from "@fluentui/react-components";

import StyledCard from "../../components/StyledCard"
import SubscriptionManagementDashboard from "./SubscriptionManagementDashboard";
import CostDashboard from "./CostDashboard";
import { EngageAppContext } from "../contexts/EngageAppContext";
import { LicensingDataContext } from "../contexts/LicensingDataContext";
import OrderHistoryDashboard from "./OrderHistoryDashboard";
import NoSubscriptionData from "./NoSubscriptionData";

const useStyles = makeStyles({
    content: {
        marginTop: tokens.spacingVerticalL,
        minHeight: '500px'
    },
    tabbedHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }

})

export default function LicensingPage() {

    const navigate = useNavigate();
    const { subscriptions } = useContext(LicensingDataContext);
    const { isLoading } = useContext(EngageAppContext);
    const [selectedTab, setSelectedTab] = useState<TabValue>("subscriptions")
    const classes = useStyles();

    const getTitle = () => {
        return (<div>Licensing</div>);
    }

    const handleAddProductClick = () => {
        navigate('/subscriptions/request');
    }

    const getTabList = () => {

        return (
            <div className={classes.tabbedHeader}>
                <TabList
                    selectedValue={selectedTab}
                    onTabSelect={handleTabSelect}>
                    <Tab value="subscriptions">Subscriptions</Tab>
                    <Tab value="cost">Cost</Tab>
                    <Tab value="history">History</Tab>
                </TabList>
                <div>
                    <Button appearance='secondary' onClick={handleAddProductClick} icon={<Add24Regular />} >Subscription</Button>
                </div>
            </div>
        )
    }

    const handleTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value)
    }

    return (
        <>
            <StyledCard
            //title={getTitle()}
            //isLoading={isLoading('useLicensingData')}
            //actions={<Button appearance='secondary' onClick={handleAddProductClick} icon={<Add24Regular />} >Product</Button>}
            >

                {!isLoading('useLicensingData') && subscriptions.length === 0
                    ? <div className={classes.tabbedHeader}>
                        <NoSubscriptionData />
                        <div>
                            <Button appearance='secondary' onClick={handleAddProductClick} icon={<Add24Regular />} >Subscription</Button>
                        </div>
                    </div>
                    :
                    <div>
                        {getTabList()}
                        <div className={classes.content}>
                            {selectedTab === "subscriptions" && <SubscriptionManagementDashboard />}
                            {selectedTab === "cost" && <CostDashboard />}
                            {selectedTab === "history" && <OrderHistoryDashboard />}
                        </div>
                    </div>}
            </StyledCard>
        </>
    )
}