import { Switch, Field, Input, Label, Body1Strong, Checkbox, SpinButton, makeStyles, tokens } from '@fluentui/react-components'
import type { SpinButtonProps, CheckboxProps } from '@fluentui/react-components';
import { useState, useEffect, useContext } from 'react';
import { formatUSD } from '../../utils';

import { EngageAppContext } from '../contexts/EngageAppContext';

const useStyles = makeStyles({
    commitmentTypeOptions: {
        display: 'flex',
        flexDirection: 'column'
    },
    enableLicensingAdminControl: {

    },
    maximumCostThresholdControl: {

        marginTop: tokens.spacingVerticalXL
    },
    allowedCommitmentTypeControl: {

        marginTop: tokens.spacingVerticalXL
    },
    enableSubscriptionRightsizingControl: {
        marginTop: tokens.spacingVerticalXL

    }
})

export default function AdminSettings() {

    const classes = useStyles();

    const commitmentTypes = ["Monthly", "1-Year", "3-Year"];
    const { adminSiteSettings, updateAdminSiteSettings, setIsComponentLoading } = useContext(EngageAppContext);

    const [maximumCostThresholdDisplay, setMaximumCostThresholdDisplay] = useState<string>(formatUSD(0));


    const onLicensingEnabledChange = async (ev: any) => {
        const updatedValue = ev.currentTarget.checked;

        let adminSiteSettingsCopy = {...adminSiteSettings};
        adminSiteSettingsCopy.isLicensingModuleEnabled = updatedValue;

        await updateAdminSiteSettings(adminSiteSettingsCopy);
    }

    const onSubscriptionRightsizingEnabledChange = async (ev: any) => {

        let adminSiteSettingsCopy = {...adminSiteSettings};
        adminSiteSettingsCopy.isSubscriptionRightsizingEnabled = ev.currentTarget.checked;

        await updateAdminSiteSettings(adminSiteSettingsCopy);

    }

    const onMaximumCostThresholdChange: SpinButtonProps['onChange'] = async (ev, data) => {
        let adminSettingsCopy = {...adminSiteSettings}; 

        // spin button pressed value is a number   
        if (data.value !== undefined && data.value !== null) {
            adminSettingsCopy.maxCalculatedOrderCostThreshold = data.value;

            await updateAdminSiteSettings(adminSettingsCopy);
            setMaximumCostThresholdDisplay(formatUSD(data.value, 2))
        } else if (data.displayValue !== undefined) {
            let newValue = parseInt(data.displayValue.replace('$', ''));
            if (!Number.isNaN(newValue)) {

                adminSettingsCopy.maxCalculatedOrderCostThreshold = newValue;
                await updateAdminSiteSettings(adminSettingsCopy);
                setMaximumCostThresholdDisplay(formatUSD(newValue))
            } else {
                // Do not submit to api
            }
        }

    }

    const onAllowedCommitmentTypeChange: CheckboxProps['onChange'] = async (event, data) => {
        const commitmentType = event.target.id;
        if (!commitmentTypes.some(value => value === commitmentType)) {
            // TODO: use Logging
            console.log("Unsupported commitmentType");
        }

        var adminSettingsCopy = {...adminSiteSettings};
        let updatedCommitmentTypes;

        if (data.checked) {
            updatedCommitmentTypes = [...adminSettingsCopy.allowedNewCommitmentTerms, event.target.id]
        }
        else {
            updatedCommitmentTypes = (adminSettingsCopy.allowedNewCommitmentTerms.filter(val => val !== commitmentType))
        }

        adminSettingsCopy.allowedNewCommitmentTerms = updatedCommitmentTypes;
        await updateAdminSiteSettings(adminSettingsCopy);

    }

   // useEffect(() => {
        //console.log('adminsitesettings', adminSiteSettings)

        //setMaximumCostThresholdDisplay(formatUSD(adminSiteSettings.maxCalculatedOrderCostThreshold));

    //}, [adminSiteSettings])

    return (
        <>
            <div className={classes.enableLicensingAdminControl}>
                <Switch
                    checked={adminSiteSettings.isLicensingModuleEnabled}
                    labelPosition='above'
                    onChange={onLicensingEnabledChange}
                    label={<Body1Strong>Enable Licensing Admin</Body1Strong>} />
            </div>
            {adminSiteSettings.isLicensingModuleEnabled &&
                <div>
                    <div className={classes.maximumCostThresholdControl}>
                        <Field
                            label={<Body1Strong>Maximum Calculated Order Cost Threshold</Body1Strong>}
                        //validationState={validEmail ? "none" : "warning"}
                        //</div>validationMessage={validEmail ? "" : "Must be valid email address"}
                        //<Input type='number' />
                        >
                            <SpinButton
                                step={100}
                                displayValue={formatUSD(adminSiteSettings.maxCalculatedOrderCostThreshold)}
                                value={adminSiteSettings.maxCalculatedOrderCostThreshold}
                                onChange={onMaximumCostThresholdChange} />
                        </Field>
                    </div>
                    <div className={classes.allowedCommitmentTypeControl}>
                        <Label>
                            <Body1Strong>
                                Allowed new subscription commitment terms
                            </Body1Strong>
                        </Label>
                        <div className={classes.commitmentTypeOptions}>
                            {commitmentTypes.map((commitmentType: string) =>
                                <Checkbox
                                    key={commitmentType}
                                    id={commitmentType}
                                    checked={adminSiteSettings.allowedNewCommitmentTerms?.some((value) => value === commitmentType)}
                                    onChange={onAllowedCommitmentTypeChange}
                                    label={commitmentType} />)}
                        </div>
                    </div>
                    <div className={classes.enableSubscriptionRightsizingControl}>
                        <Switch
                            labelPosition='above'
                            label={<Body1Strong>Enable subscription rightsizing</Body1Strong>}
                            checked={adminSiteSettings.isSubscriptionRightsizingEnabled}
                            onChange={onSubscriptionRightsizingEnabledChange} />

                    </div>
                </div>
            }
        </>
    )

}