import { useNotificationsData } from '../../hooks/useNotificationsData';
import { createContext} from 'react';


export const NotificationsDataContext = createContext({
    notifications: [] as any[],
    isLoading: false,
    error: {}
});

export const NotificationsDataProvider = ({children}: {children: any}) => {

    const { notifications, isLoading, error } = useNotificationsData();

        return (
            <NotificationsDataContext.Provider value={{notifications, isLoading, error}}>
                {children}
            </NotificationsDataContext.Provider>
        );

}