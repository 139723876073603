import { useContext, useState } from "react";
import { tokens, Popover, PopoverTrigger, PopoverSurface, Link, Button, makeStyles } from "@fluentui/react-components"
import { dateTime } from "../../utils";
import { LicensingDataContext } from "../contexts/LicensingDataContext";

const useStyles = makeStyles({
    statusDescription: {
        marginBottom: '10px',
        maxWidth: '350px',
        marginTop: tokens.spacingVerticalS
    },
    actionButtons: {
        width: '350px',
        display: 'flex',
        gap: tokens.spacingHorizontalM
    }
});

export default function ActionPopover(props: any) {

    const classes = useStyles();
    const { unfulfilledOrder } = props;
    const { updateSubscriptionsOrderStatus } = useContext(LicensingDataContext);

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenChange = (event: any, data: any) => {
        console.log('handleOpenChange-inFile', data);
        setIsOpen(data.open);
    }

    const handleActionClick = (unfulfilledOrder: any, action: any) => {
        console.log('handleActionClick-inFile', unfulfilledOrder, action);
        updateSubscriptionsOrderStatus(unfulfilledOrder.engageOrderId, unfulfilledOrder.subscriptionId, action);
        setIsOpen(false);
        console.log('handleActionCLick-isOpen', isOpen);
    }

    return (
        <>
            <Popover open={isOpen} onOpenChange={handleOpenChange} withArrow>
                <PopoverTrigger>
                    <Link>{unfulfilledOrder.status}</Link>
                </PopoverTrigger>
                <PopoverSurface>
                    <div>Order created by: {unfulfilledOrder.contactName}</div>
                    <div>Order status: {unfulfilledOrder.status}</div>
                    <div>Status date: {dateTime(unfulfilledOrder.statusDate)}</div>
                    <div className={classes.statusDescription}>{unfulfilledOrder.statusMessage}</div>
                    <br />
                    <div className={classes.actionButtons}>
                        {
                            unfulfilledOrder.actions.map((action: any) => {
                                return (<Button key={action} onClick={() => handleActionClick(unfulfilledOrder, action)}>{action == "Cancel" ? "Cancel Order" : action}</Button>)
                            })
                        }
                    </div>
                </PopoverSurface>
            </Popover>
        </>
    )


}