import { useLicensingData } from '../../hooks/useLicensingData';
import { createContext} from 'react';

const licensingContextDefault = {
    subscriptions: [] as any[],
    orders: [] as any[],
    getSubscriptions: () => Promise.resolve({} as any),
    updateSubscriptions: (tenantId:string, subscriptions:any) => Promise.resolve(),
    setSubscriptionAutoZeroStatus : (subscriptionId:string, autozero:boolean) => Promise.resolve() ,
    updateSubscriptionsOrderStatus: (orderId:string, subscriptionId:string, action:string) => Promise.resolve(),
}

export const LicensingDataContext = createContext(licensingContextDefault);

//export const EngageAppContext = createContext(
   //{
    //isLoading: false,
    //setIsLoading: (loadingValue:boolean) => { },
    //adminSiteSettings: test,
    //setAdminSiteSettings: (settings:any) => { } 
//}
//);



export const LicensingDataProvider = ({children}: {children: any}) => {

    const {subscriptions, updateSubscriptions, orders, setSubscriptionAutoZeroStatus, getSubscriptions, updateSubscriptionsOrderStatus} = useLicensingData();

        return (
            <LicensingDataContext.Provider value={{subscriptions, updateSubscriptions, orders, setSubscriptionAutoZeroStatus, getSubscriptions, updateSubscriptionsOrderStatus}}>
                {children}
            </LicensingDataContext.Provider>
        );

}