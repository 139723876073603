import { useEffect, useState, useContext } from 'react';
import { useRest } from './useRest';
import { AccountsDataContext } from '../app/contexts/AccountsDataContext';
import { LoggingContext } from '../app/contexts/LoggingContext';
import { ReportingDetails } from '../types/reportingTypes';
import { xDaysFromCurrentDate } from '../utils';

export const useReportingData = () => {
    const logFilePrefix = 'useReportingData';
    const { accounts, accountError } = useContext(AccountsDataContext);
    const { trackTraceVerbose, trackTraceWarning } = useContext(LoggingContext);

    const daysAgo90 = xDaysFromCurrentDate(-90);
    const today = new Date();

    const [reportingDetails, setReportingDetails] = useState<ReportingDetails>();
    const [reportingDates, setReportingDates] = useState<Date[]>([daysAgo90, today]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportingError, setReportingError] = useState<any | undefined>(undefined);
    const { GET } = useRest();

    useEffect(() => {

        (async () => {
            const logName = `${logFilePrefix}-useEffect`;

            trackTraceVerbose(`${logName}`);
            if (accountError) {
                trackTraceVerbose(`${logName}-accountError: ${accountError}`);
                setReportingError(accountError);
                return;
            }

            if (!accounts || accounts.length === 0) {
                trackTraceWarning(`${logName}-guardClause: deps[accounts, reportingDates]`)
                return;
            }

            setIsLoading(true);

            const selectedAccount = accounts.filter(x => x.selected)[0];

            if (!selectedAccount?.tenantId)
                return;

            const dateQueryString = `start=${reportingDates[0].toISOString()}&end=${reportingDates[1].toISOString()}`;

            const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/reporting/${selectedAccount.tenantId}?${dateQueryString}`);

            if (!response.ok) {
                setReportingError({ errorMessage: "Error retrieving notifications. Please try again later." });
                return;
            }

            const reportingDetails = await response.json() as ReportingDetails;

            setReportingDetails(reportingDetails);
            setIsLoading(false);

        })();

    }, [accounts, reportingDates, accountError]);

    return {
        reportingDetails,
        isLoading,
        setReportingDates,
        reportingDates,
        reportingError
    }
}