import { useState, useContext, useEffect } from "react";
import { TabList, Tab, Divider, makeStyles, tokens } from "@fluentui/react-components"
import type {
    SelectTabData,
    SelectTabEvent,
    TabValue,
} from "@fluentui/react-components";

import StyledCard from "../../components/StyledCard"
import PermissionsSettings from "./Permissions";
import LicensingSettings from "./Licensing";
import AdminSettings from "./Admin";
import { EngageAppContext } from "../contexts/EngageAppContext";
import { ContactsDataContext } from "../contexts/ContactsDataContext";

const useStyles = makeStyles({
    content: {
        marginTop: tokens.spacingVerticalL,
        minHeight: '500px'
    }
})

export default function Settings() {

        const adminName = 'admin';
        const licensingName = 'licensing';
        const permissionsName = 'permissions';


    const {adminSiteSettings} = useContext(EngageAppContext);
    const { contact } = useContext(ContactsDataContext)

    const [selectedTab, setSelectedTab] = useState<TabValue>("permissions")
    const classes = useStyles();

    const getTitle = () => {

        return (
            <TabList
                selectedValue={selectedTab}
                onTabSelect={handleTabSelect}>
                <Tab value="permissions">Permissions</Tab>
                { adminSiteSettings.isLicensingModuleEnabled && canAccess(licensingName) && <Tab value={licensingName}>Licensing</Tab> }
                { canAccess(adminName) && <Tab value={adminName}>Admin</Tab> }
            </TabList>
        )
    }

    const handleTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value)
    }

    const canAccess = (navName:string) => {
        if (!contact)
        {
            return false;   
        }

        const isAdministrator = contact.userRoles.some(x => x == 'Administrator');

        if (navName == adminName) {

            if (contact === undefined) 
                return false; 

            return isAdministrator;
        }

        if (navName == licensingName) {

            if (isAdministrator)
                return true;
            
            // Only Pax8LicensingAdmin can modify recipients/autotrim
            if (contact.userRoles.some(x => x == 'Pax8LicensingAdmin'))
                return true;

            return false;
        }
    }

    return (
        <>
            <StyledCard
                title={"Configuration"}>
                
                <div>
                    {getTitle()}
                    <Divider
                        alignContent="start"
                        appearance="strong" />
                    <div className={classes.content}>
                        {selectedTab === "permissions" && <PermissionsSettings />}
                        {selectedTab === "licensing" && <LicensingSettings />}
                        {selectedTab === "admin" && <AdminSettings />}
                    </div>
                </div>
            </StyledCard>
        </>
    )
}