import { makeStyles, tokens, mergeClasses } from "@fluentui/react-components";
import { Add16Regular, Subtract16Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    positive: {
        color: tokens.colorStatusSuccessForeground1
    },
    negative: {
        color: tokens.colorStatusDangerForeground1
    }

})

export default function QuantityChange(props: any) {
    const { initialValue, updatedValue } = props;

    const classes = useStyles();

    const delta = updatedValue - initialValue;

    const getChangedValueDisplay = (delta: number) => {

        if (delta === 0) {
            // no changes
            return <></>
        }

        if (delta > 0) {
            // increment
            return <strong className={mergeClasses(classes.container, classes.positive)}>+{delta}</strong>
        }

        // decrement
        return <strong className={mergeClasses(classes.container, classes.negative)}>−{Math.abs(delta)}</strong>

    }

    return (
        <>
            {getChangedValueDisplay(delta)}
        </>
    )
}