import {
    Table
} from '@fluentui/react-components';
import { useTableStyles } from '../styles';

export default function EngageTable(props:any) {

    const {children } = props
    const tableClasses = useTableStyles();
    return (
        <>
            <Table className={tableClasses.table} size='small'>
                {children}
            </Table>
        </>
    )
}