import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);

export const date = (date: Date) => {
    var formattedDate = dayjs(date).format('MM/DD/YYYY');
    return formattedDate;
}

export const dateTime = (date: Date) => {
    return dayjs(date).format('MM/DD/YYYY hh:mm A');
}

export const dateTimeLong = (date: Date) => {
    return dayjs(date).format('MMMM D, h:mm A');
}

export const dateUTC = (date: Date) => {
    return dayjs(date).utc().format('MM/DD/YYYY');
}

export const xDaysFromCurrentDate = (days: number) => {
    return dayjs().add(days, 'day').toDate();
}

export const formatUSD = (dollarAmount: number, precision: number = 2) => {
    try {
        const fixedPrecision = dollarAmount.toFixed(precision);
        const withSeparators = parseFloat(fixedPrecision).toLocaleString(undefined, {style: 'currency', currency:"USD"})
        return withSeparators;
    }
    catch (err) {
        console.log(JSON.stringify(err))
        return dollarAmount.toFixed(precision);
    }
}