'use client';

import { useContext } from 'react'

import {
    makeStyles,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    mergeClasses,
    shorthands
} from '@fluentui/react-components';
import { useMediaQuery } from 'react-responsive';
import StyledCard from '../StyledCard';
import { STATUSES, STATUS_TYPES } from '../../app/constants';
import NoTickets from './NoTickets';
import { TicketsDataContext } from '../../app/contexts/TicketsDataContext';
import { IdentityDataContext } from '../../app/contexts/IdentityDataContext';
import { dateTime } from '../../utils';
import { fonts } from '../../styles';
import { useNavigate } from 'react-router-dom';
import { useTableStyles } from '../../styles';
import EngageTable from '../EngageTable';

export default function OpenTickets({ className }) {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    const { tickets, ticketsLoading, ticketError } = useContext(TicketsDataContext);
    const { roles } = useContext(IdentityDataContext);
    const navigate = useNavigate();

    let openCases = [];
    if (!ticketError) {
        openCases = tickets.filter(x => {
            return x.statusType === STATUS_TYPES.OPEN && x.status != STATUSES.ON_HOLD
        }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    const tableClasses = useTableStyles();
    const displayTicketCreator = roles && (roles[0] === 'ServiceManager.Read') ? true : false;

    return (
        <>
            <StyledCard
                className={className}
                title='Open Tickets'
                isLoading={ticketsLoading}
                error={ticketError}
            >
                {openCases?.length > 0 ?
                    <EngageTable>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Title</TableHeaderCell>
                                {displayTicketCreator && isDesktopOrLaptop &&
                                    <TableHeaderCell >Ticket Creator</TableHeaderCell>}
                                {isDesktopOrLaptop &&
                                    <>
                                        <TableHeaderCell >Status</TableHeaderCell>
                                        <TableHeaderCell >Created</TableHeaderCell>
                                    </>
                                }
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                openCases.map((x) => {
                                    return (
                                        <TableRow key={x.id}
                                            onClick={() => navigate(`/ticketDetails/${x.id}`)}
                                            className={tableClasses.rowClickable}>
                                            <TableCell style={{ width: '350px' }}
                                                className={tableClasses.rowClickable}>
                                                {x.subject}
                                            </TableCell>
                                            {displayTicketCreator && isDesktopOrLaptop &&
                                                <TableCell>
                                                    <span>{x.contactName}</span>
                                                </TableCell>}
                                            {isDesktopOrLaptop &&
                                                <>
                                                    <TableCell>
                                                        {x.status}
                                                    </TableCell>
                                                    <TableCell>
                                                        {dateTime(x.createdAt)}
                                                    </TableCell>
                                                </>
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </EngageTable>
                    : <NoTickets message='There are no open tickets assigned to you' />
                }
            </StyledCard>
        </>
    )
};