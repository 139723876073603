import {
    DonutChart,
}
    from '@fluentui/react-charting';
import StyledCard from '../StyledCard';
import NoData from './NoData';

import { TicketOverviewReport } from '../../types/reportingTypes';

function CasesOverview({ casesOverviewData, className }: { casesOverviewData: TicketOverviewReport, className?: string }) {

    const points = [
        { legend: 'Open', data: casesOverviewData.openTickets ?? 0, color: '#0099BC' },
        { legend: 'On Hold', data: casesOverviewData.onHoldTickets ?? 0, color: '#77004D' },
        { legend: 'Closed', data: casesOverviewData.closedTickets ?? 0, color: '#4f67ed', }
    ];

    const data = {
        chartTitle: 'Donut chart basic example',
        chartData: points,
    };

    const total = points.reduce((acc, val) => {

        return acc + val.data

    }, 0);

    return (
        <StyledCard title='Tickets Overview' className={className}>
            {total === 0 ? <NoData /> :
                <DonutChart
                    data={data}
                    innerRadius={55}
                    hideLegend={false}
                    hideLabels={true}
                    height={250}
                    //width={300}
                    valueInsideDonut={total}
                    legendProps={{
                        allowFocusOnLegends: true
                    }}
                />
            }
        </StyledCard>
    );
}

export default CasesOverview;