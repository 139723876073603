import {
    makeStyles,
    tokens,
    Card,
    Divider,
    Button,
    ProgressBar
} from '@fluentui/react-components';

import { NavLink, useNavigate } from 'react-router-dom';

import {
    Add20Filled,
    DataHistogram24Regular,
    PersonSupport24Filled,
    PersonSupport24Regular,
    Settings24Regular,
    StoreMicrosoft24Regular,
} from '@fluentui/react-icons'


import { fonts } from '../../styles';
import { ENGAGE_USER_ROLES, APPLICATION_ROLES } from '../../app/constants';
import { EngageAppContext } from '../../app/contexts/EngageAppContext';
import { IdentityDataContext } from '../../app/contexts/IdentityDataContext';
import { AccountsDataContext } from '../../app/contexts/AccountsDataContext';
import { useContext, useState, useEffect } from 'react';
import AccountDropdown from '../AccountDropdown';
import SupportPopover from './SupportPopover';
import NotificationIcon from './NotificationIcon';
import { useMediaQuery } from 'react-responsive';
import { ContactsDataContext } from '../../app/contexts/ContactsDataContext';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    menuItemM: {
        marginRight: tokens.spacingHorizontalM,
    },
    avatar: {
        height: '36px',
    },
    accountDropdown: {
        marginLeft: tokens.spacingHorizontalM,
    },
    questionIcon: {
        marginRight: tokens.spacingHorizontalM,
        marginLeft: tokens.spacingHorizontalM,
    },
    // TODO Dedupe this with the linkClasses
    linkClassesActive: {
        ...fonts.title,
        color: tokens.colorBrandForegroundInverted,

        textDecorationLine: 'none',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
    },
    linkClasses: {

        ...fonts.title,
        color: tokens.colorNeutralForeground1,

        textDecorationLine: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center'
    },
    createCasesButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        ...fonts.tableHeader
    },
    plusIcon: {
        marginRight: tokens.spacingHorizontalXS,
    },
    mobileHeaderContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

    },
    mobileAccountDropdown: {
        marginTop: tokens.spacingVerticalM,
    },
    mobileHeaderTopRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    mobileHeaderNavigation: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    mobileQuestionIcon: {
        marginLeft: tokens.spacingHorizontalM,
    },
    mobileMoreNav: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingHorizontalS
    },
    headerContainer: {
        position: 'relative'
    },
    progressBar: {
        position: 'absolute',
        bottom: 0,
        left: 0
    }
});

const linkOptions = [
    {
        icon: <PersonSupport24Regular />,
        path: '/',
        text: 'Home',
        margin: 'Medium',
        roles: []
    },
    {
        icon: <NotificationIcon />,
        path: '/notifications',
        text: 'Notifications',
        roles: []
    },
    {
        icon: <StoreMicrosoft24Regular />,
        path: '/subscriptions',
        text: 'Subscriptions',
        margin: 'Medium',
        roles: [
            ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
            ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER
        ]
    },
    {
        icon: <DataHistogram24Regular />,
        path: '/reports',
        text: 'Reports',
        margin: 'Medium',
        roles: [APPLICATION_ROLES.SERVICE_MANAGER]
    }
]


function Header() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [links, setLinks] = useState<any[]>(linkOptions);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isLargerThanMobile = useMediaQuery({ query: '(min-width: 1000px)' });
    const { isLoading, adminSiteSettings, loadingArray } = useContext(EngageAppContext);
    const { roles, hasApplicationUserRole } = useContext(IdentityDataContext);
    const { accounts } = useContext(AccountsDataContext);
    const { contact } = useContext(ContactsDataContext);


    const selectedAccount = accounts.filter(a => a.selected)[0];
    const isServiceManager = roles && roles.includes('ServiceManager.Read');


    const hasRequiredRole = (requiredRoles: any[]) => {

        if (!contact) {
            return false;
        }

        let result = false;
        requiredRoles.forEach(role => {
            if (contact.userRoles.some(x => x == role)) {
                result = true;
                return;
            }

            if (roles.includes(role)) {
                result = true;
                return;
            }
        });

        return result;
    };

    useEffect(() => {
        if (!contact)
            return;

        if (!adminSiteSettings.isLicensingModuleEnabled || !isLargerThanMobile ) {
            setLinks(linkOptions.filter((x: any) => x.text != "Subscriptions"))
        }
        else {
            setLinks(linkOptions);
        }

    }, [adminSiteSettings, contact, isLargerThanMobile]);

    const handleCreateTicketClick = () => {
        navigate('/createTicket');
    }

    const DefaultHeader = () => {
        return (<><div className={classes.headerLeft}>
            <Button appearance='primary' onClick={handleCreateTicketClick}>
                <div className={classes.createCasesButton}>
                    <span className={classes.plusIcon}><Add20Filled /></span>
                    <span>Ticket</span>
                </div>
            </Button>
            {
                accounts.length > 1 && selectedAccount &&
                <div className={classes.accountDropdown}>
                    <AccountDropdown accounts={accounts} selectedAccount={selectedAccount} />
                </div>
            }
        </div>
            <>

                <div className={classes.headerRight}>

                    {
                        links.map(link => {

                            // Show links with no role, or show links that match the users first role
                            if (link.roles.length === 0 || hasRequiredRole(link.roles)) {

                                const marginClass = link.margin === 'Medium' ? classes.menuItemM : undefined;

                                return (
                                    <div key={link.path} className={marginClass}>

                                        <NavLink
                                            to={link.path}
                                            className={({ isActive, isPending }) => isActive ? classes.linkClassesActive : isPending ? "pending" : classes.linkClasses}
                                        >
                                            {link.icon}
                                        </NavLink>
                                    </div>
                                )
                            }
                            else
                                return null;
                        })
                    }
                    <Divider vertical />
                    <div className={classes.questionIcon}>
                        <SupportPopover />
                    </div>
                    <div>
                        <NavLink
                            to={'/settings'}
                            className={({ isActive, isPending }) => isActive ? classes.linkClassesActive : isPending ? "pending" : classes.linkClasses}>
                            <Settings24Regular />
                        </NavLink>
                    </div>
                    {
                        /* Removing this for now, possibly will be added back in standalone version
                            <ContactPopover />
                        */
                    }
                </div>

            </>
        </>);
    }

    const MobileHeader = () => {
        return (
            <>
                <div className={classes.mobileHeaderContent}>
                    <div className={classes.mobileHeaderTopRow}>
                        <Button appearance='primary' onClick={handleCreateTicketClick}>
                            <div className={classes.createCasesButton}>
                                <span className={classes.plusIcon}><Add20Filled /></span>
                                <span>Ticket</span>
                            </div>
                        </Button>
                        <div className={classes.mobileHeaderNavigation}>
                            {
                                links.map(link => {

                                    // Show links with no role, or show links that match the users first role
                                    if (link.roles.length === 0 || (isServiceManager)) {

                                        const marginClass = link.margin === 'Medium' ? classes.menuItemM : undefined;

                                        return (
                                            <div key={link.path} className={marginClass}>

                                                <NavLink
                                                    to={link.path}
                                                    className={({ isActive, isPending }) => isActive ? classes.linkClassesActive : isPending ? "pending" : classes.linkClasses}
                                                >
                                                    {link.icon}
                                                </NavLink>
                                            </div>
                                        )
                                    }
                                    else
                                        return null;
                                })
                            }
                            <Divider vertical />
                            <div className={classes.mobileMoreNav}>
                                <div className={classes.mobileQuestionIcon}>
                                    <SupportPopover />
                                </div>
                                <div>
                                    <NavLink
                                        to={'/settings'}
                                        className={({ isActive, isPending }) => isActive ? classes.linkClassesActive : isPending ? "pending" : classes.linkClasses}>
                                        <Settings24Regular />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        accounts.length > 1 && selectedAccount &&
                        <div className={classes.mobileAccountDropdown}>
                            <div>
                                <AccountDropdown accounts={accounts} selectedAccount={selectedAccount} />
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }

    return (
        <>
            <div className={classes.headerContainer}>
                <Card>
                    <div className={classes.header}>
                        {isDesktopOrLaptop ? <DefaultHeader /> : <MobileHeader />}
                    </div>
                </Card>
                {isLoading() &&
                    <ProgressBar className={classes.progressBar} shape='rounded' thickness='large' />
                }
            </div>
        </>
    );

}


export default Header;

