import { ApplicationInsights, IEventTelemetry, ICustomProperties, ITraceTelemetry, SeverityLevel, ITelemetryItem } from '@microsoft/applicationinsights-web'


export const useLogging = () => {

    let appInsights: any = null
    if (process.env.REACT_APP_IS_LOCAL === 'true') {

        const trackTrace = (message: string, severityLevel: number) => {
            console.log(`severityLevel: ${severityLevel}`, { message })
        }

        appInsights = {
            authenticatedUserContext: {
                userId: '',
                accountId: ''
            },
            trackPageView: (pageName: string) => {
                console.log('trackPageView', pageName)
            },
            trackEvent: (eventName: string, customProperties: any) => {
                const body = { ...customProperties, authenticatedUserContext: appInsights.authenticatedUserContext }
                console.log('trackEvent', { eventName, body })
            },
            trackException: (error: Error) => {
                const exceptionBody = { ...error, authenticatedUserContext: appInsights.authenticatedUserContext }
                console.log('trackException', exceptionBody)
            },
            trackTrace: (telem: ITraceTelemetry) => {
                console.log(`severityLevel: ${telem.severityLevel} ${telem.message}`)
            },
            context: {
                getSessionId: () => {
                    return 'sessionId'
                }
            }            
        }
    }
    else {
        appInsights = new ApplicationInsights(
            {
                config: {
                    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
                    /* disabled because it does not track route navigation accurately 
                    //enableAutoRouteTracking: true,
                    */
                    enableCorsCorrelation: true,

                }
            }
        ) as ApplicationInsights;

        appInsights.loadAppInsights();

    }

    //appInsights.queue.push(() => {
    //appInsights.addTelemetryInitializer((envelope:any) => {

    //envelope.tags['ai.cloud.role'] = 'Engage-React-SPA';
    //envelope.tags['ai.cloud.roleInstance'] = '';
    //});
    //});

    const setAuthenticatedUserContext = (userId: string, accountId: string) => {
        if (process.env.REACT_APP_IS_LOCAL === 'true') {
            appInsights.authenticatedUserContext = {
                userId: userId,
                accountId: accountId
            }

            return;
        }

        appInsights.setAuthenticatedUserContext(userId, accountId);
    }



    const trackPageView = (pageName: string) => {
        appInsights.trackPageView({ name: pageName });
    }

    const trackEvent = (eventName: string, customProperties: any) => {
        appInsights.trackEvent({ name: eventName } as IEventTelemetry, { ...customProperties } as ICustomProperties);
    }

    const trackException = (error: Error) => {
        appInsights.trackException({ exception: error });
    }

    const trackTrace = (telem: ITraceTelemetry) => {
        appInsights.trackTrace(telem);
    }

    // extra
    const trackTraceVerbose = (message: string) => {
        const payload = { message: message, severityLevel: SeverityLevel.Verbose } as ITraceTelemetry
        trackTrace(payload);
    }

    // information
    const trackTraceInformation = (message: string) => {
        const payload = { message: message, severityLevel: SeverityLevel.Information } as ITraceTelemetry
        trackTrace(payload);
    }

    // warning with workaround
    const trackTraceWarning = (message: string) => {
        const payload = { message: message, severityLevel: SeverityLevel.Warning } as ITraceTelemetry
        trackTrace(payload);
    }

    // error with workaround
    const trackTraceError = (message: string) => {
        const payload = { message: message, severityLevel: SeverityLevel.Error } as ITraceTelemetry
        trackTrace(payload);
    }

    // Error with no workaround
    const trackTraceCritical = (message: string) => {
        const payload = { message: message, severityLevel: SeverityLevel.Critical } as ITraceTelemetry
        trackTrace(payload);
    }

    const getSessionId = ():string => {
        return appInsights.context.getSessionId();
    }

    return {
        trackPageView,
        trackEvent,
        trackException,
        trackTraceVerbose,
        trackTraceInformation,
        trackTraceWarning,
        trackTraceError,
        trackTraceCritical,
        setAuthenticatedUserContext,
        getSessionId: () => getSessionId()
    }
}