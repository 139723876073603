import { useState, useContext } from 'react'
import { AccountsDataContext } from '../app/contexts/AccountsDataContext';
import { EngageAppContext } from '../app/contexts/EngageAppContext';
import { Account } from '../types/accountTypes';
import {
    Dropdown,
    Option
} from '@fluentui/react-components'

export default function AccountDropdown({ accounts, selectedAccount }: { accounts: Account[], selectedAccount: Account }) {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([selectedAccount.tenantId]);
    const [value, setValue] = useState<string>(selectedAccount.tenantName);
    const { setAccounts } = useContext(AccountsDataContext);
    const {isLoading} = useContext(EngageAppContext);

    const onOptionSelect = (ev: any, data: any) => {
        setSelectedOptions(data.selectedOptions);
        setValue(data.optionText)

         const updatedAccounts = accounts.map(account => ({
            ...account,
            selected: account.tenantId === data.optionValue
        }));

        setAccounts(updatedAccounts);
    };



    return (
        <>
            <Dropdown
                disabled={isLoading()}
                value={value}
                onOptionSelect={onOptionSelect}
                selectedOptions={selectedOptions}
            >
                {
                    accounts.length > 0 && accounts.map(account => {
                        return (
                            <Option key={account.tenantId} value={account.tenantId} text={account.tenantName}>{account.tenantName}</Option>
                        )
                    })
                }
            </Dropdown>
        </>
    );
}