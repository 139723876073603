import React, { useContext, useState, useEffect } from 'react';
import { Spinner } from "@fluentui/react-components";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { CLIENTS } from "../app/constants";
import { TeamsFxContext } from "../app/Context";
import { PublicClientApplication, InteractionType, Configuration } from "@azure/msal-browser";
import { scopes } from "../app/constants";
import { LoggingContext } from '../app/contexts/LoggingContext';
import { app } from "@microsoft/teams-js";

export default function AuthProvider({
    children,
}: {
    children: React.ReactNode,
}) {
    const logFilePrefix = 'authenticationProvider';

    const { trackTraceVerbose, trackTraceInformation, trackException, trackEvent, trackTraceCritical } = useContext(LoggingContext);
    const [host, setHost] = useState('unknown');
    const [clientType, setClientType] = useState('unknown');

    /* Teams Auth */

    const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
        initiateLoginEndpoint: `${process.env.REACT_APP_INITIATE_LOGIN_ENDPOINT}/auth-start.html`,
        clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID as string
    });

    /* End Teams Auth */

    trackTraceVerbose(`${logFilePrefix}-teamsUserCredential-loading: ${loading}`);

    useEffect(() => {
        const logName = `${logFilePrefix}-useEffect`;

        if (loading) {
            return;
        }

        (async () => {
            trackTraceVerbose(`${logName}-teamsUserCredential-loading: ${loading}`);
            trackTraceVerbose(`${logName}-teams-initialize`);

            try {
                await app.initialize();

                trackTraceVerbose(`${logName}-teams-initialize-success`);
                const context = await app.getContext();

                const appHostInfo = context.app.host;
                const host = appHostInfo.name;
                setHost(host);
                setClientType(appHostInfo.clientType);
                trackEvent(`applicationHost: ${host}`, { host })

                trackTraceInformation(`${logName}-teams-initialize-set-host: ${appHostInfo.name}`)
                trackTraceInformation(`${logName}-teams-initialize-client-type: ${appHostInfo.clientType}`)

                app.notifySuccess();
            }
            catch (err: any) {
                const host = 'standalone';
                setHost(host);
                trackEvent(`applicationHost: ${host}`, { host })
                trackTraceVerbose(`${logName}-teams-initialize-error: ${err.message}`)
                
                // Occurs when teams cannot be initialized, such as when running in a standalone environment
                if (err.message !== "Initialization Failed. No Parent window found.") {
                  trackException(err);
                }
            }
        })();
    }, [loading]);

    /// Helper method to move MSAL configuration into a separate method
    /// Might consider moving to a helper file
    const getMsalProvider = () => {
        /* MSAL Auth */
        trackTraceVerbose(`${logFilePrefix}-msal-configure`);
        const configuration: Configuration = {
            auth: {
                clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID as string,
                redirectUri: process.env.REACT_APP_INITIATE_LOGIN_ENDPOINT, //will default to "window.location.origin" if not specified, will cause problems if a non root page is loaded
                authority: "https://login.microsoftonline.com/common" // default
            }
        };

        trackTraceVerbose(`${logFilePrefix}-msal-publicClientApplication`);
        const pca = new PublicClientApplication(configuration);

        const authRequest = {
            scopes
        };
        /* End MSAL Auth */

        trackTraceVerbose(`${logFilePrefix}-applying-msal-provider`)
        return (
            <MsalProvider instance={pca}>
                <UnauthenticatedTemplate>
                    <span>Logging in...</span>
                </UnauthenticatedTemplate>

                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                    {children}
                </MsalAuthenticationTemplate>
            </MsalProvider>
        );
    }

    let useTeamsFx = false;
    const inTeams = host.toLowerCase().includes("teams");
    const inOutlook = host.toLowerCase().includes("outlook");
    const inOffice = host.toLowerCase().includes("office");

    useTeamsFx = inTeams || inOutlook || inOffice;

    // Check whether or not the host includes teams in the name
    if (useTeamsFx) {
        trackTraceVerbose(`${logFilePrefix}-applying-teamsfxContext-provider`)
        trackTraceVerbose(`${logFilePrefix}-teamsfxContext-provider-themeString: ${themeString}`)

        let _theme = themeString;
        if (themeString === 'default') {
            _theme = 'light';
        }

        trackEvent(`${logFilePrefix}-theme`, { theme: _theme });
        return (
            <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential, host }}>
                {children}
            </TeamsFxContext.Provider>
        );

    }
    // MSAL Authentication
    else if (host.toLowerCase().includes("standalone")) {
        const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        trackEvent(`${logFilePrefix}-theme`, { theme: prefersDark ? 'dark' : 'light' });
        return getMsalProvider();
    } else {
        trackTraceVerbose(`authenticationProvider-applying-unauthenticated-template`)
        return (
            <div>
                <span>Awaiting Authentication Method Discovery...</span>
                <Spinner />
            </div>
        );
    }


}