import StyledCard from '../../components/StyledCard';
import { Button } from '@fluentui/react-components';
import { Open16Regular } from '@fluentui/react-icons';
export default function Welcome() {

        const handleButtonClick = () => {
        //trackEvent('leaveFeedbackClicked', {});
        window.open(`https://www.greatservice.com/contact-us`, '_blank');
    }

    return (
        <>
            <StyledCard
                title='Customer Account not found'>

                <div>
                    <p>
                        Engage is a tool built to facilitate communication between Summit Technology and its' customers.
                    </p>
                    <p>
                        You are seeing this page because you do not belong to an account authorized to use the Engage Application.
                    </p>
                    <div>
                        <p>
                            <strong>A business relationship with Summit Technology is required.</strong>
                        </p>
                        <p>
                            <Button
                                icon={<Open16Regular />}
                                iconPosition='after'
                                appearance='primary'
                                onClick={handleButtonClick}

                            >Contact Summit Technology</Button>

                        </p>
                    </div>
                </div>
            </StyledCard>
        </>
    );
}