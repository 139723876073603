import { useState } from 'react';
import { makeStyles, shorthands, tokens, Spinner } from '@fluentui/react-components';

import { useMediaQuery } from 'react-responsive';
import {
    BoldItalicUnderlineToggles,
    InsertThematicBreak,
    ListsToggle,
    MDXEditor,
    RealmPlugin,
    Separator,
    UndoRedo,
    headingsPlugin,
    InsertImage,
    imagePlugin,
    listsPlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    markdownShortcutPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

import { useRest } from '../../src/hooks/useRest';

const useStyles = makeStyles({
    detailsMultiLine: {
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    }
});

export default function Editor(props: any) {

    const { editorText, onEditorChange } = props;

    const classes = useStyles();

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const [uploading, setUploading] = useState(false);
    const { POST } = useRest();

    const imageUploadHandler = async (file: File) => {
        setUploading(true);
        const response = await POST(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Files/`, file)
        const imageUrl = await response.text();
        setUploading(false);
        return imageUrl;
    }

    const editerPlugins = [
        headingsPlugin(),
        listsPlugin(),
        thematicBreakPlugin(),
        imagePlugin({ imageUploadHandler }),
        toolbarPlugin({
            toolbarContents: () => (
                <>
                    {' '}
                    <UndoRedo />
                    <Separator />
                    <BoldItalicUnderlineToggles />
                    <Separator />
                    <ListsToggle options={['bullet', 'number']} />
                    <Separator />
                    <InsertImage />
                    <InsertThematicBreak />

                </>
            )
        }),
        markdownShortcutPlugin()
    ];
    const mobileEditorPlugins = [
        headingsPlugin(),
        listsPlugin(),
        thematicBreakPlugin(),
        imagePlugin({ imageUploadHandler }),
        toolbarPlugin({
            toolbarContents: () => (
                <>
                    {' '}
                    <UndoRedo />
                    <Separator />
                    <BoldItalicUnderlineToggles />
                </>
            )
        })
    ];


    return (
        <div>
            <MDXEditor
                className={classes.detailsMultiLine}
                markdown={editorText}
                onChange={onEditorChange}
                plugins={isDesktopOrLaptop ? editerPlugins : mobileEditorPlugins}
            />
            {uploading && <Spinner size='extra-small' label='Uploading...' />}
        </div>
    )
}