import { makeStyles, tokens, shorthands } from "@fluentui/react-components";
import { fonts } from '../../styles'

const useStyles = makeStyles({
    message: {
        display: "flex",
        justifyContent: "center",

        ...fonts.title,
        ...shorthands.margin("5px","0px", "5px")
        // Need to use tokens to choose font and size // TODO create style guide
    },
});

export default function NoTickets() {
    const messageContent = "No data";

    const classes = useStyles();

    return (
        <>
            <div className={classes.message}>{messageContent}</div>
        </>
    );
}