import {
    makeStyles,
} from '@fluentui/react-components'

import {
    HorizontalBarChart
}
    from '@fluentui/react-charting';
import StyledCard from '../StyledCard';
import NoData from './NoData';

function TopTicketSubmitters({ highestTicketSubmitterData, className }: any) {
    const total = highestTicketSubmitterData.totalTickets;
    const data = highestTicketSubmitterData.topTicketSubmitters.map((employee: any) => {
        return {
            chartTitle: employee.name,
            chartData: [{
                legend: employee.name,
                horizontalBarChartdata: { x: employee.value, y: total },
                color: '#4f67ed'
            }]
        }
    });

    return (
        <>
            <StyledCard title="Top Ticket Submitters" className={className}>
                { data.length === 0 ? <NoData /> : 
                <HorizontalBarChart
                    data={data}
                />
                }
            </StyledCard>
        </>
    );
}

export default TopTicketSubmitters;