
import { createContext } from 'react';
import useContactsData from '../../hooks/useContactsData';

export const ContactsDataContext = createContext({
    contact: { id: '', fullName: '', phone: '', email: '', jobTitle: '', assignedLicenses: [], userRoles: [] },
    hasEngageUserRole: (role: string) => false as boolean
});

export const ContactsDataProvider = ({ children }: { children: any }) => {

    const { 
        contact,
        hasEngageUserRole
    } = useContactsData();

    return (
        <ContactsDataContext.Provider
            value={{
                contact: contact,
                hasEngageUserRole
            }}>
            {children}
        </ContactsDataContext.Provider>
    );
};