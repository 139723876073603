import { useIdentity } from '../../hooks/useIdentity';
import { createContext} from 'react';


export const IdentityDataContext = createContext({
    tenantId: '' as string,
    getAccessToken: () => Promise.resolve('') as Promise<string>,
    roles: [] as string[],
    login: () => {},
    signInRequired: false as boolean,
    authError: false as boolean,
    hasApplicationUserRole : (role:string) => false as boolean
});

export const IdentityDataProvider = ({children}: {children: any}) => {

    const { tenantId, roles, getAccessToken, login, signInRequired, authError, hasApplicationUserRole } = useIdentity();

        return (
            <IdentityDataContext.Provider value={{
                tenantId,
                roles,
                getAccessToken,
                login,
                signInRequired,
                authError,
                hasApplicationUserRole
            }}>
                {children}
            </IdentityDataContext.Provider>
        );

}