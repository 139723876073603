import { useReportingData } from '../../hooks/useReportingData';
import { createContext } from 'react';


export const ReportingDataContext = createContext({
    reportingDetails: {} as any,
    isLoading: false,
    setReportingDates: (val: Date[]) => {},
    reportingDates: [] as Date[],
    reportingError: undefined as any
});

export const ReportingDataProvider = ({ children }: { children: any }) => {

    const { reportingDetails, isLoading, setReportingDates, reportingDates, reportingError } = useReportingData()

    return (
        <ReportingDataContext.Provider value={{ reportingDetails, isLoading, setReportingDates, reportingDates, reportingError }}>
            {children}
        </ReportingDataContext.Provider>
    );

}