import { useState, useContext } from 'react';

import {
    TagGroup,
    Tag,
    InteractionTag,
    Button,
    makeStyles,
    tokens,
    shorthands,
    InteractionTagPrimary,
    InteractionTagSecondary
} from "@fluentui/react-components";

import { Dismiss20Regular, ArrowDownload20Regular, WarningRegular } from '@fluentui/react-icons';

import Dropzone from 'react-dropzone';

import { TicketsDataContext } from '../app/contexts/TicketsDataContext';

const useStyles = makeStyles({
    tagGroup: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    // FileTag Styles
    tags: {
        marginTop: tokens.spacingVerticalS,
    },
    dropzone: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
        cursor: 'pointer',

        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        ...shorthands.border('1px', 'dashed', tokens.colorNeutralStroke1),
    },
    dropzoneDroppable: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
        cursor: 'pointer',

        backgroundColor: tokens.colorBrandBackground2Hover,
        ...shorthands.border('1px', 'dashed', tokens.colorBrandStroke1),
        ...shorthands.borderRadius(tokens.borderRadiusSmall),

        color: tokens.colorBrandForeground2
    },
    rejectedFiles: {
        ...shorthands.border('1px', 'solid', tokens.colorStatusDangerForeground1),
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        color: tokens.colorStatusDangerForeground1,
        backgroundColor: tokens.colorStatusDangerBackground1,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

    }
});

export default function TicketAttachments(props: any) {
    const { files, onFilesChange } = props;

    const [rejectedFiles, setRejectedFiles] = useState<any[]>([])

    const { downloadAttachment, deleteAttachment } = useContext(TicketsDataContext);

    const handleOnDrop = (acceptedFiles: any) => {
        setRejectedFiles([]);
        onFilesChange([...files, ...acceptedFiles]);
    }

    const handleRejectedDrop = (rejected: any) => {
        setRejectedFiles(rejected);
    }

    const handleDismissAttachment = async (_e: any, { value }: { value: any }) => {
        const dismissedAttachment = files.find((attachment: any) => attachment.name === value);
        
        if (dismissedAttachment.id) {
            await deleteAttachment(dismissedAttachment.ticketId, dismissedAttachment.id);
            // Delete the attachment from the server
        }

        onFilesChange([...files].filter((attachment) => attachment.name !== value));
    }

    const handleDownloadAttachment = async (attachment: any) => {
        
        // Ajax call will not trigger download
        // This is a workaround to trigger the download...
        const x = await downloadAttachment(attachment.ticketId, attachment.id) as any;
        var blob = await x.blob()
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = attachment.name;
        a.href = url;
        a.click();
        a.href = '';
    }

    const classes = useStyles();

    return (
        <>
            <Dropzone
                onDrop={handleOnDrop}
                onDropRejected={handleRejectedDrop}
                // This is 120 MB in bytes
                maxSize={125_829_120}
                onDragOver={() => console.log('onDragOver')}
                onDragEnter={() => console.log('onDragEnter')}
                onDragLeave={() => console.log('onDragLeave')}>
                {({ getRootProps, getInputProps, isDragActive, }) => (
                    <div className={isDragActive ? classes.dropzoneDroppable : classes.dropzone} {...getRootProps()}>
                        <input {...getInputProps()} />
                        Drop files here to attach, or click to browse.
                    </div>
                )}
            </Dropzone>
            <TagGroup className={classes.tagGroup} onDismiss={handleDismissAttachment}>
                {files.map((attachment: any, index: any) => {

                    return (
                        attachment.id ? 
                        <InteractionTag className={classes.tags}
                            shape="circular"
                            appearance="brand"
                            id={attachment.id}
                            key={index}
                            value={attachment.name}
                        >
                            <InteractionTagPrimary 
                                primaryText={attachment.name} 
                                id={attachment.id} 
                                value={attachment.id} 
                                onClick={() => handleDownloadAttachment(attachment)} 
                                icon={<ArrowDownload20Regular />} 
                                hasSecondaryAction>{attachment.name}</InteractionTagPrimary>
                            <InteractionTagSecondary />
                        </InteractionTag>
                        : <Tag 
                            title="File not saved yet. Press 'Upload Attachment' button."
                            shape="circular"
                            appearance="outline"
                            dismissible    
                            className={classes.tags}
                            key={index}
                            value={attachment.name}
                            icon={<WarningRegular />}>{attachment.name}</Tag>)
                }
                )}
            </TagGroup>
            {
                rejectedFiles.length > 0 &&
                <div className={classes.rejectedFiles}>
                    <div>
                        {rejectedFiles.map((file: any, index: any) => {
                            return (<li key={index}>{file.file.name} - <strong>{file.errors[0].code == 'file-too-large' ? 'File must be smaller than 120MB' : ''}</strong></li>)
                        })}
                    </div>
                    <div><Button appearance='transparent' icon={<Dismiss20Regular />} onClick={() => setRejectedFiles([])}></Button></div>
                </div>
            }
        </>

    )
}