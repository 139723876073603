import { useLogging } from '../../hooks/useLogging';
import { createContext } from 'react';


export const LoggingContext = createContext({
    trackEvent: (eventName: string, customProperties: any) => { },
    trackTraceVerbose: (message: string) => { },
    trackTraceInformation: (message: string) => { },
    trackTraceWarning: (message: string) => { },
    trackTraceError: (message: string) => { },
    trackTraceCritical: (message: string) => { },
    trackException: (error: Error) => { },
    trackPageView: (pageName: string) => { },
    setAuthenticatedUserContext: (userId: string, userName: string) => { },
    getSessionId: () => "" as string,
});

export const LoggingProvider = ({ children }: { children: any }) => {

    const {
        trackEvent,
        trackTraceVerbose,
        trackTraceInformation,
        trackTraceWarning,
        trackTraceError,
        trackTraceCritical,
        trackException,
        trackPageView,
        setAuthenticatedUserContext,
        getSessionId
    } = useLogging();

    return (
        <LoggingContext.Provider value={
            {
                trackEvent,
                trackTraceVerbose,
                trackTraceInformation,
                trackTraceWarning,
                trackTraceError,
                trackTraceCritical,
                trackException,
                trackPageView,
                setAuthenticatedUserContext,
                getSessionId
            }}>
            {children}
        </LoggingContext.Provider>
    );

}