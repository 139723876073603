import { useState, useEffect, useContext } from "react";
import { Button, Divider, Field, Input, Label, tokens, TagGroup, Tag, makeStyles, useId, Body1Strong } from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";
import TrimSubscription from "./licensing/TrimSubscription";
import { EngageAppContext } from "../contexts/EngageAppContext";

const useStyles = makeStyles({
    inputBox: {
        maxWidth: '500px'
    },
    notificationEmailField: {
        verticalAlign: 'center',
    },
    recipientTagList: {
        marginTop: tokens.spacingVerticalS,
        display: 'flex',
        flexWrap: 'wrap'
    },
    tag: {
        marginTop: tokens.spacingVerticalXS
    },
    adminSettings: {
        marginTop: tokens.spacingVerticalXL
    },
    notificationRecipientsFieldControl: {
        marginBottom: tokens.spacingVerticalXXXL
    }
})

export default function LicensingSettings() {

    const [validEmail, setValidEmail] = useState<boolean>(true);
    const [recipientInput, setRecipientInput] = useState("");
    const tagField = useId('licenseNotificationRecipients')

    const { adminSiteSettings, tenantSiteSettings, updateTenantSiteSettings, setIsComponentLoading } = useContext(EngageAppContext);

    const classes = useStyles()

    const notificationField = "notificationEmailField"

    const handleOnKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            submitEmail();
        }
        else {
            console.log('do not submit')
        }

    }

    const submitEmail = async () => {

        if (recipientInput === "") {
            return;
        }

        if (validateEmail(recipientInput)) {
            // TODO: Store Recipient in dataverse...

            var tenantSiteSettingsCopy = tenantSiteSettings;
            tenantSiteSettingsCopy.licensingNotificationRecipients = [...tenantSiteSettingsCopy.licensingNotificationRecipients, recipientInput]


            try {
                setIsComponentLoading("UpdateLicensingRecipients", true)

                await updateTenantSiteSettings(tenantSiteSettingsCopy)
            }
            catch (err: any) {

            }
            finally {

                setIsComponentLoading("UpdateLicensingRecipients", false)
            }

            setRecipientInput("");
            setValidEmail(true);
        } else {
            setValidEmail(false);
            console.log('not valid');
        }
    }

    const onInputChange = (event: any, data: any) => {
        setRecipientInput(data.value)
    }

    const handleClearInput = () => {
        setRecipientInput("");
        setValidEmail(true);
    }

    const removeRecipient = async (event: any, data: any) => {
        var tenantSiteSettingsCopy = tenantSiteSettings;
        tenantSiteSettingsCopy.licensingNotificationRecipients = tenantSiteSettingsCopy.licensingNotificationRecipients.filter((x: any) => x !== data.value)

        try {
            setIsComponentLoading("UpdateLicensingRecipients", true)
            await updateTenantSiteSettings(tenantSiteSettingsCopy)
        }
        catch (err: any) {

        }
        finally {

            setIsComponentLoading("UpdateLicensingRecipients", false)
        }
    };

    const validateEmail = (email: string) => {
        // email regex, salamat po, SO
        //const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // Simplified this because.
        // <text>@<text>.<text>
        const re =
            /\S*@\S*\.\S*/;
        return re.test(String(email).toLowerCase());
    };

    useEffect(() => {

        // TODO: Retrieve receipients
        // setNotificationRecipients(tenantSiteSettings.licensingNotificationRecipients)

    }, [tenantSiteSettings])

    return (
        <div>
            {  /*   RECIPIENT EMAIL FIELD    */}
            <div className={classes.notificationRecipientsFieldControl}>
                <div className={classes[notificationField]}>
                    <Field
                        label={<Body1Strong>Licensing change notification recipients:</Body1Strong>}
                        validationState={validEmail ? "none" : "warning"}
                        validationMessage={validEmail ? "" : "Must be valid email address"}>
                        <Input
                            placeholder="Enter recipent email address"
                            id={notificationField}
                            value={recipientInput}
                            className={classes.inputBox}
                            onChange={onInputChange}
                            onKeyDown={handleOnKeyPress}
                            onBlur={submitEmail}
                            contentAfter={recipientInput &&
                                <Button
                                    icon={<Dismiss20Regular />}
                                    size="small"
                                    appearance="transparent"
                                    onClick={handleClearInput} />
                            } />
                    </Field>
                </div>
                <div className={classes.recipientTagList}>
                    <TagGroup onDismiss={removeRecipient}>
                        {
                            tenantSiteSettings.licensingNotificationRecipients.map((x: any) => {
                                return (
                                    <Tag
                                        id={x}
                                        key={x}
                                        appearance="brand"
                                        shape="circular"
                                        className={classes.tag}
                                        dismissible={true}>{x}</Tag>
                                )
                            })
                        }
                    </TagGroup>
                </div>
            </div>

            { /*   Trimmed Subscriptions List */}
            {adminSiteSettings.isSubscriptionRightsizingEnabled &&
                <TrimSubscription />
            }
        </div>
    )
}