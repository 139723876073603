import { useContext } from 'react';
import { Table, TableRow, TableHeader, TableHeaderCell, TableBody, TableCell, makeStyles, tokens, Body1, Caption1Strong, themeToTokensObject } from '@fluentui/react-components';
import { LicensingDataContext } from '../contexts/LicensingDataContext'
import { EngageAppContext } from '../contexts/EngageAppContext';
import QuantityChange from '../../components/QuantityChange';
import { fonts } from '../../styles';
import { dateTime } from '../../utils';
import EngageTable from '../../components/EngageTable';
import NoSubscriptionData from './NoSubscriptionData';

const useStyles = makeStyles({
    quantityColumn: {
        display: 'flex',
        width: '130px',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '10px'
    },
    quantityValue: {
        display: 'flex'
    },
    productNameColumn: {
        width: '300px',
        minWidth: '300px',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '10px'
    },
    tableBody: {
        ...fonts.body
    },
    changedByColumn: {

    },

    standardColumn: {
        width: '75px'
    },
    largeColumn: {
        width: '130px',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '10px'
    },
    footerRow: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginTop: tokens.spacingVerticalL
    }
});

export default function OrderHistoryDashboard() {

    const { orders } = useContext(LicensingDataContext);
    const { isLoading } = useContext(EngageAppContext);

    const classes = useStyles();

    return (
        <>
            {!isLoading('useLicensingData') &&
                <EngageTable>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell className={classes.productNameColumn}>Subscription Name</TableHeaderCell>
                            <TableHeaderCell className={classes.standardColumn}>Commit</TableHeaderCell>
                            <TableHeaderCell className={classes.standardColumn}>Quantity</TableHeaderCell>
                            <TableHeaderCell className={classes.largeColumn}>Changed by</TableHeaderCell>
                            <TableHeaderCell className={classes.largeColumn}>Provision Date</TableHeaderCell>
                            <TableHeaderCell className={classes.standardColumn}>Order Status</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody className={classes.tableBody}>
                        {orders.map(x =>
                            <TableRow key={`${x.pax8OrderId}-${x.subscriptionId}-${x.startDate}`}>
                                <TableCell className={classes.productNameColumn}>{x.productName}</TableCell>
                                <TableCell className={classes.standardColumn}>{x.commitmentTerm}{x.billingTerm === 'Annual' ? <sub><Caption1Strong>†</Caption1Strong></sub> : null}</TableCell>
                                <TableCell className={classes.standardColumn}>
                                    <div className={classes.quantityColumn}>
                                        <div className={classes.quantityValue}>{x.newQuantity}</div>
                                            (<QuantityChange initialValue={x.originalQuantity} updatedValue={x.newQuantity} />)
                                    </div>
                                </TableCell>
                                <TableCell className={classes.largeColumn}>{x.orderedBy}</TableCell>
                                <TableCell className={classes.largeColumn}>{dateTime(x.startDate)}</TableCell>
                                <TableCell className={classes.standardColumn}>{x.engageStatus}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </EngageTable>
            }
            {isLoading('useLicensingData') && <Body1>Loading licensing data</Body1>}
            <div className={classes.footerRow}>
                <Caption1Strong>† Billed Annually instead of Monthly</Caption1Strong>
            </div>
        </>
    )
}