import {
  tokens,
} from '@fluentui/react-components'

import {
  HorizontalBarChart,
  HorizontalBarChartVariant
} from '@fluentui/react-charting';
import { TopTicketCategoryReport } from '../../types/reportingTypes';
import StyledCard from '../StyledCard';
import NoData from './NoData';

function TopTicketCategories({ topTicketCategoryReport, className }: { topTicketCategoryReport: TopTicketCategoryReport, className?: string }) {

  const chartDataPoints = topTicketCategoryReport.categories.map((x) => {
    return {
      chartTitle: x.name,
      chartData: [
        {
          legend: x.name,
          horizontalBarChartdata: { x: x.value, y: topTicketCategoryReport.totalTickets },
          color: tokens.colorBrandForeground1
        }
      ]
    }
  });

  return (
    <>
      <StyledCard className={className} title="Top Ticket Categories">
        {chartDataPoints.length === 0 ? <NoData /> :
          <HorizontalBarChart
            data={chartDataPoints}
            variant={HorizontalBarChartVariant.PartToWhole} />
        }
      </StyledCard>
    </>
  );
}

export default TopTicketCategories;