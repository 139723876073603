import { useContext } from "react";
import { Badge, makeStyles, tokens } from "@fluentui/react-components";
import { Alert24Regular } from "@fluentui/react-icons";
import { NotificationsDataContext } from "../../app/contexts/NotificationsDataContext";

const useStyles = makeStyles({
    notificationIcon: {
        marginRight: tokens.spacingHorizontalM,
    },
    marginXXS: {
        marginRight: tokens.spacingHorizontalXXS,
    },
    marginM: {
        marginRight: tokens.spacingHorizontalM,
    },
    notificationBadge: {
        position: 'relative',
        left: '-10px',
    }
});

export default function NotificationIcon() {

    const { notifications, isLoading } = useContext(NotificationsDataContext);

    const classes = useStyles();

    const showBadge = () => {

        const unResolvedNotifications = notifications.filter(n => n.status === 'Active');

        return unResolvedNotifications.length > 0;
    }
    
    const notificationClass = () => {
        return showBadge() ? classes.marginXXS : classes.marginM;
    }

    return (
        <>
            <span className={notificationClass()}>
            <Alert24Regular />
            {
                showBadge() &&
                <Badge className={classes.notificationBadge} size='extra-small' color='danger' />
            }</span>
</>
    );
}