import { useState, useEffect, useContext } from 'react';
import { AccountsDataContext } from '../app/contexts/AccountsDataContext';
import { LoggingContext } from '../app/contexts/LoggingContext';
import { useRest } from './useRest';

export const useNotificationsData = () => {
    const logFilePrefix = 'useNotificationsData';

    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any | undefined>(undefined);
    const { accounts, accountError } = useContext(AccountsDataContext);
    const { trackTraceVerbose, trackTraceWarning } = useContext(LoggingContext);
    const { GET } = useRest();

    useEffect(() => {
        (async () => {

            const logName = `${logFilePrefix}-useEffect`;

            if(!accounts) {
                trackTraceWarning(`${logName}-guardClause: deps[accounts]`);
                return;
            }

            trackTraceVerbose(`${logName}`);

            if (accountError) {
                trackTraceVerbose(`${logName}-accountError`);
                setIsLoading(false);
                setError(accountError);
                return;
            }

            setIsLoading(true);

            const selectedAccount = accounts.filter((x: any) => x.selected)[0];

            if (!selectedAccount?.tenantId) {
                return;
            }

            trackTraceVerbose(`${logName}-retrieving-notifications`);
            const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/notifications/${selectedAccount.tenantId}`);

            if (!response.ok) {
                setError('Error retrieving notifications. Please try again later.');
                setIsLoading(false);
                return;
            }

            const data = await response.json();

            setNotifications(data);
            setIsLoading(false);
        })();
    }, [accounts, accountError]);

    return {
        notifications,
        isLoading,
        error
    }

}