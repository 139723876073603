import DOMPurify from 'dompurify';

import {
    Card,
    CardHeader,
    CardFooter,
    makeStyles,
    tokens
} from '@fluentui/react-components'

import {
    Info24Regular,
    Warning24Regular,
    CheckmarkCircle24Regular
} from '@fluentui/react-icons'

import { date } from '../../utils'
import {fonts} from '../../styles'

const useStyles = makeStyles({
    notification: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'left',

        minWidth: '100%'
    },
    card: {
        minWidth: '100%'
    },
    avatar: {
        marginTop: '5px',
        marginRight: '5px'
    },
    cardBody: {
        '& h3': {
            textAlign: 'center'
        }
    },
    titleText: {
        marginLeft: '5px',

        ...fonts.cardTitle
    },
    timeText: {
        ...fonts.cardSubtitle
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center'
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    alert: {
        color: tokens.colorPaletteRedForeground1
    },
    resolved: {
        color: tokens.colorPaletteGreenForeground1
    },
    info: {
        color: tokens.colorPaletteBlueForeground2
    }
})

function Notification({ posterName, postTime, header, body, resolutionBody, notificationType, status }: any) {
    const classes = useStyles();

    // Setting inner html requires this format
    // Only set inner html if we control the html source
    // XSS vulneralbility otherwise
    const bodyHTML = { __html: DOMPurify.sanitize(body) }
    const resolutionBodyHTML = { __html: DOMPurify.sanitize(resolutionBody) }
    const isResolved = notificationType === 'Alert' && status === 'Resolved';

    return (
        <>
            {/*
                <StyledCard title={getHeader()} className={classes.notification}>

                    {isResolved && resolutionBody &&
                        <div>
                            <div dangerouslySetInnerHTML={resolutionBodyHTML}></div>
                            <hr />
                        </div>
                    }
                    <div dangerouslySetInnerHTML={bodyHTML}></div>
                </StyledCard>
                */}

            <div className={classes.notification}>
                <Card className={classes.card}>
                    <CardHeader
                        header={getHeader()}
                    />

                    {isResolved && resolutionBody &&
                        <div>
                            <div dangerouslySetInnerHTML={resolutionBodyHTML}></div>
                            <hr />
                        </div>
                    }
                    <div dangerouslySetInnerHTML={bodyHTML}></div>
                    <CardFooter>
                        {date(postTime)}
                    </CardFooter>
                </Card>
            </div>
        </>
    );

    function getHeader() {
        let headerIconComponent;
        let headerIconClass;
        let headerText = header;

        if (status === 'Resolved' && notificationType === 'Alert') {
            headerIconComponent = <CheckmarkCircle24Regular />
            headerIconClass = classes.resolved;
            headerText = `Resolved - ${headerText}`;
        }
        else if (status !== 'Resolved' && notificationType === 'Alert') {
            headerIconComponent = <Warning24Regular />
            headerIconClass= classes.alert
        }
        else {
            headerIconComponent = <Info24Regular />
            headerIconClass= classes.info
        }

        return (
            <div className={classes.cardHeader}>
                <div className={classes.cardTitle}>
                    <div className={headerIconClass}>
                        {headerIconComponent}
                    </div>
                    <div className={classes.titleText}>{headerText}</div>
                </div>
            </div>
        )
    }
}

export default Notification;