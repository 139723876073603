'use client';

import {
    makeStyles,
    tokens,
    Spinner
} from '@fluentui/react-components';

import { useContext, useEffect } from 'react';

import StyledCard from '../../components/StyledCard';
import Notification from '../../components/notifications/Notification';
import { NotificationsDataContext } from '../contexts/NotificationsDataContext';
import { LoggingContext } from '../contexts/LoggingContext'
import ErrorPage from '../../error-page';

const useStyles = makeStyles({
    app: {
        backgroundColor: tokens.colorNeutralBackground3,
    },
    defaultNotice: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spinner: {
        paddingTop: '180px'
    }
});

const Notifications = () => {

    const { notifications, isLoading, error } = useContext(NotificationsDataContext);
    const { trackPageView, getSessionId } = useContext(LoggingContext);
    const classes = useStyles()

    useEffect(() => {
        trackPageView('notifications');
    }, []);

    return (
        <>
            {
                error && <ErrorPage sessionId={getSessionId()} />
            }
            {isLoading ? <div className={classes.spinner}><Spinner size='huge' /></div> :

                notifications.length > 0 ? notifications.map(x => {
                    return (<Notification
                        key={x.notificationId}
                        posterName="Summit Technology"
                        postTime={x.startDate}
                        header={x.subject}
                        body={x.body}
                        resolutionBody={x.resolutionBody}
                        notificationType={x.notificationType}
                        status={x.status} />
                    )
                })

                    : <div className={classes.defaultNotice}>No notifications check back later</div>
            }
        </>
    );
}

export default Notifications;
