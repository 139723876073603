'use client';

import {
    useContext
} from 'react';


import { TicketsDataContext } from '../../app/contexts/TicketsDataContext';

import StyledCard from '../StyledCard';
import {
    makeStyles,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    shorthands

} from '@fluentui/react-components';
import { useMediaQuery } from 'react-responsive';
import { STATUSES, STATUS_TYPES } from '../../app/constants';

import NoTickets from './NoTickets';
import { fonts, useTableStyles } from '../../styles';
import { dateTime } from '../../utils';
import { IdentityDataContext } from '../../app/contexts/IdentityDataContext';

import { useNavigate } from 'react-router-dom';
import EngageTable from '../EngageTable';

export default function OnHoldTickets({ className }) {

    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    const { tickets, ticketsLoading, ticketError } = useContext(TicketsDataContext);
    const { roles } = useContext(IdentityDataContext);

    let onHoldCases = [];
    if (!ticketError) {
        onHoldCases = tickets.filter(x => {
            return x.statusType === STATUS_TYPES.OPEN && x.status === STATUSES.ON_HOLD
        }).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    }

    const mergedClasses = className;
    const tableClasses = useTableStyles();

    const displayTicketCreator = roles && (roles[0] === 'ServiceManager.Read' || roles[0] === 'Administrator.Read') ? true : false;
    
    return (
        <StyledCard
            className={mergedClasses}
            title='On Hold Tickets'
            isLoading={ticketsLoading}
            error={ticketError}
        >
            {onHoldCases?.length > 0 ?
                <EngageTable>
                    <TableHeader>
                        <TableRow>
                            {/* TODO This needs to be generated based on API Request */}
                            <TableHeaderCell>Title</TableHeaderCell>
                            {displayTicketCreator && isDesktopOrLaptop && <TableHeaderCell>Ticket Creator</TableHeaderCell>}
                            {isDesktopOrLaptop &&
                                <>
                                    <TableHeaderCell>Created</TableHeaderCell>
                                    <TableHeaderCell>Updated</TableHeaderCell>
                                </>
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {onHoldCases.map((x) => {
                            return (
                                <TableRow
                                    className={tableClasses.rowClickable}
                                    key={x.id}
                                    onClick={() => navigate(`/ticketDetails/${x.id}`)}
                                >

                                    <TableCell className={tableClasses.truncatedTableData}
                                    >
                                        {x.subject}
                                    </TableCell>
                                    {displayTicketCreator && isDesktopOrLaptop && <TableCell>{x.contactName}</TableCell>}
                                    {isDesktopOrLaptop && <>
                                        <TableCell>{dateTime(x.createdAt)}</TableCell>
                                        <TableCell>{dateTime(x.updatedAt)}</TableCell></>
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </EngageTable>
                : <NoTickets message='There are no on hold tickets assigned to you' />
            }
        </StyledCard>
    )
};