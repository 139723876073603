import { EngageAppContext } from "../app/contexts/EngageAppContext";
import { IdentityDataContext } from "../app/contexts/IdentityDataContext";
import { LoggingContext } from "../app/contexts/LoggingContext";
import { useContext } from "react";

const defaultOptions = {
    mode: 'cors'
};

export const useRest = () => {

    const { getAccessToken, login } = useContext(IdentityDataContext)
    const { setIsComponentLoading } = useContext(EngageAppContext);
    const { trackException, trackEvent, trackTraceWarning, trackTraceError, trackTraceCritical } = useContext(LoggingContext);

    const GET = async (endpoint: string): Promise<Response> => {

        trackEvent('GET', { endpoint });

        const options = {
            method: 'GET',
        };

        const headers = new Headers();

        return await send(endpoint, headers, options)

    }

    const POSTForm = async (endpoint:string, payload:any) => {
        trackEvent('POSTForm', { endpoint, payload });

        const options = {
            method: 'POST',
            body: payload
        }
        
        const myHeaders = new Headers();

        return await send(endpoint, myHeaders, options)

    }

    const POST = async (endpoint: string, payload: any) => {
        trackEvent('POST', { endpoint, payload });

        const options = {
            method: 'POST',
            body: payload
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        return await send(endpoint, myHeaders, options)

    }

    const DELETE = async (endpoint: string) => {
        trackEvent('DELETE', { endpoint });

        const options = {
            method: 'DELETE',
        }

        const myHeaders = new Headers();

        return await send(endpoint, myHeaders, options)
    }

    const send = async (endpoint: string, headers:Headers, options?: any): Promise<Response> => {

        let accessToken = await getAccessToken();
        if (!accessToken) {
            return Promise.reject('Unable to retrieve access token.');
        }

        const _options = {
            ...defaultOptions,
            ...options
        };

        let needsRetry = false;
        let attempts = 0;
        let response: Response;

        do {
            attempts++;
            const authorizationHeader = `Bearer ${accessToken}`;

           //const myHeaders = new Headers();
           // if (_options.method === 'POST') {
                ////myHeaders.append("Content-Type", "multipart/form-data");
            //}
            //else {

                //myHeaders.append("Content-Type", "application/json");
            //}

            headers.append("Authorization", authorizationHeader);
            try {
                setIsComponentLoading(`${_options.method}:${endpoint}`, true);
                response = await fetch(`${endpoint}`, {
                    ..._options,
                    headers
                })
            }
            catch (error: any) {
                if (error.message === 'Failed to fetch') {
                    trackTraceCritical(`Failed to fetch: ${endpoint}`);
                    response = new Response(null, { status: 503, statusText: 'Service Not Available' });
                }
                else {
                    trackTraceCritical(`Error: ${error.message}`);
                    response = new Response(null, { status: 500, statusText: error.message });
                }

                trackException(error);
                return response;
            }
            finally {
                setIsComponentLoading(`${_options.method}:${endpoint}`, false);
            }

            // If a 401 is encountered it may mean the token has expired
            // Attempt to refresh the token and retry the request
            if (response.status === 401) {
                await login();
                needsRetry = true;
            }
        }

        while (needsRetry && attempts < 2)

        return response;
    }

    return {
        GET,
        POST,
        POSTForm,
        DELETE
    }
}