import {formatUSD} from "../../src/utils" 

export default function Currency(props :any) {
    const { dollarAmount } = props

    return (
        <>
            <span>{formatUSD(dollarAmount)}</span>
        </>
    )
}